import { ListPagination } from "Common/ListPagination";
import BetListModal from "Common/Modals/BetListModal";
import MarketPositionModal from "Common/Modals/MarketPositionModal";
import NewsModal from "Common/Modals/NewsModal";
import TableContainer from "Common/TableContainer";
import TableEntry from "Common/TableEntry";
import moment from "moment";
import {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  ReactPortal,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Dropdown,
  Form,
  Modal,
  Overlay,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import { settingServices } from "Utils/setting/services";
import snackbarUtil from "Utils/snackBarUtil";

interface Props {
  pendingData: pendingResponse[];
  page: number;
  limit: number;
  listTotal: ProfitLossTotal;
  handlePageFilter: (value: number, limit: number) => void;
}

const MatchResultTable = ({
  pendingData,
  page,
  limit,
  listTotal,
  handlePageFilter,
}: Props) => {
  const [showResultPopover, setShowResultPopover] = useState<string | null>(null);
  const [showResult, setResult] = useState(false);
  const [showAbonded, setAbonded] = useState(false);
  const [showBook, setBook] = useState(false);
  const [showBet, setBet] = useState(false);
  const [showNews, setNews] = useState(false);
  const [selectionId, setSelectionId] = useState<string>("");
  const [marketId, setMarket] = useState<string>("");
  const [matchedId, setMatchedId] = useState<string>("");
  const [marketName, setMarketName] = useState<string>("");
  const [newsValues, setNewsValues] = useState<string>("");
  const [resultData, setResultData] = useState<ResultResponse | null>();
  const [selectionError, setSelectionError] = useState("");
  const [loadingResult, setLoadingResult] = useState(false);

  const target = useRef(null);
  const targetRefs = useRef<{ [key: string]: HTMLElement | null }>({});

  const toggleResult = (id: string) => {

    setMarket(id);
    setResult(!showResult);
  };

  const toggleAbonded = (id: string) => {
    setMarket(id);
    setAbonded(!showAbonded);
  };

  const toggleBook = (id: string, name: string) => {
    setMarket(id);
    setBook(!showBook);
    setMarketName(name);
  };
  const toggleBet = (id: string, matchId: string, name: string) => {
    setMarket(id);
    setMatchedId(matchId);
    setMarketName(name);
    setBet(!showBet);
  };
  const toggleNews = (id: string) => {
    setMarket(id);
    setNews(!showNews);
  };

  const getOddsResult = async () => {
    const { response } = await settingServices.setOddsResult({
      market_id: marketId,
      selection_id: selectionId,
    });
    if (response?.status) {
      snackbarUtil?.success(response?.msg);
    } else {
      snackbarUtil?.error(response?.msg);
    }
  };
  const getOddsABD = async () => {
    const { response } = await settingServices.getOddsABD({
      market_id: marketId,
    });
    if (response?.status) {
      snackbarUtil?.success(response?.msg);
    } else {
      snackbarUtil?.error(response?.msg);
    }
  };

  const handleSelectRinner = (e: any) => {
    const { value } = e.target;
    setSelectionId(value);
    setSelectionError("");
  };

  const handleUpdateResult = () => {
    if (!selectionId) {
      snackbarUtil.error("Please select a selection before updating the result.");
      return;
    }
    getOddsResult();
    setResult(false);
  };
  const handleUpdateAbd = () => {
    getOddsABD();
    setAbonded(false);
  };

  const getUpdateEventNews = async (data: any) => {
    const { response } = await authServices?.updateEvent({
      event: data?.eventName,
      filter: {
        [data?.sportName]: data?.sportId,
      },
      update: {
        news: newsValues,
      },
    });
    if (response?.status) {
      snackbarUtil.success(response?.data);
      setNews(false);
    } else {
      snackbarUtil.error(response?.data);
    }
  };

  const handleUpdateNews = () => {
    const data = {
      eventName: "market",
      sportName: "market_id",
      sportId: marketId,
    };
    getUpdateEventNews(data);
  };

  const getResult = async (market_id: string) => {
    try {
        setLoadingResult(true);
        const { response } = await settingServices.getResult({ market_id });
 
        if (!response || !response.data || !response.data.runners) {
            console.error("Error: Missing data in response");
            setResultData(null);
            setLoadingResult(false);
            return;
        }
 
        const winnerData = response.data.runners.find(
            (item: any) => item.status === "WINNER"
        );
 
        console.log(winnerData, "winnerDatawinnerData");
        setResultData(winnerData || null);
    } catch (error) {
        console.error("Error fetching result:", error);
        setResultData(null);
    } finally {
        setLoadingResult(false);
    }
 };

  const handleViewResult = async (id: string) => {
    await getResult(id);
    setShowResultPopover((prev) => (prev === id ? null : id));
  };

  const handleClosePopover = () => {
    setShowResultPopover(null);
    setResultData(null);
  };

  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "no",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return ((page - 1) * limit) + cell.row.index + 1;
        },
      },
      {
        header: "Sport Name",
        accessorKey: "sport_name",
        enableColumnFilter: false,
      },
      {
        header: "Series Name",
        accessorKey: "series_name",
        enableColumnFilter: false,
      },
      {
        header: "Match Name",
        accessorKey: "match_name",
        enableColumnFilter: false,
      },
      {
        header: "Market Name",
        accessorKey: "market_name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              {cell.row.original?.market_name} ({cell.row.original?.bet_count})
            </div>
          );
        },
      },
      {
        header: "Result",
        accessorKey: "result",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const marketId = cell?.row?.original?.market_id;
          return (
            <>
              <OverlayTrigger
                trigger="click"
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-${marketId}`}>
                    <span>Result</span> <br />
                    {resultData ? (
                      `Winner Team is ${resultData.name}`
                    ) : (
                      "No result available."
                    )}
                  </Tooltip>
                }
              >
                <Button
                  variant="subtle-primary"
                  onClick={() => handleViewResult(marketId)}
                  className="btn-sm"
                >
                  View
                </Button>
              </OverlayTrigger>
            </>
          );
        },
      },
      {
        header: "Selection Name",
        accessorKey: "runners",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <Form.Select onChange={(e) => handleSelectRinner(e)}>
                <option value="">Select..</option>
                {cell.row.original?.runners?.map(
                  (items: { selection_name: string; selection_id: number }) => {
                    return (
                      <option value={items?.selection_id}>
                        {items?.selection_name}
                      </option>
                    );
                  }
                )}
                {selectionError && (
                  <div className="text-danger mt-1">{selectionError}</div>
                )}
              </Form.Select>
            </div>
          );
        },
      },
      {
        header: "Date",
        accessorKey: "date",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              {moment(cell?.row?.original?.createdAt).format(
                "DD-MM-YYYY, hh:mm:ss A"
              )}
            </div>
          );
        },
      },
      {
        header: "Action",
        accessorKey: "action",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <>
              <div className="d-flx">
                <Button className="btn-sm me-1" onClick={() => toggleResult(cell?.row?.original?.market_id)}>Result</Button>
                <Button variant="subtle-danger" className="btn-sm me-1" onClick={() => toggleAbonded(cell?.row?.original?.market_id)}>Abonded</Button>
                <Button variant="info" className="btn-sm me-1" onClick={() =>
                  toggleBook(
                    cell?.row?.original?.market_id,
                    cell?.row?.original?.market_name
                  )
                }>Book</Button>
                <Button variant="subtle-primary" className="btn-sm me-1" onClick={() =>
                  toggleBet(
                    cell?.row?.original?.market_id,
                    cell?.row?.original?.match_id,
                    cell?.row?.original?.match_name
                  )
                }>Bet</Button>
                <Button variant="subtle-dark" className="btn-sm me-1" onClick={() => toggleNews(cell?.row?.original?.market_id)}>News</Button>
              </div>
            </>
          );
        },
      },
    ],
    [page, limit, resultData]
  );

  return (
    <>
      <TableContainer
        columns={columns || []}
        data={pendingData || []}
        isBordered={false}
        customPageSize={limit}
        isPagination={false}
        sorting={true}
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
      />

      <nav aria-label="Page navigation" className="px-3 d-flex flex-column flex-sm-row align-items-center">
        <TableEntry
          limit={limit}
          handlelimitFilter={(value: number) => handlePageFilter(page, value)}
        />
        <ListPagination
          listTotal={listTotal}
          handlePageFilter={(value: number) => handlePageFilter(value, limit)}
        />
      </nav>

      <Modal
        show={showResult}
        onHide={() => toggleResult("")}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <span>Update Result</span>
          <Button variant="light btn-sm" onClick={() => toggleResult("")}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="fs-md">
          <p className="fs-lg">
            Are you sure want to update <strong>Result</strong>?
          </p>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button className="ms-2" onClick={handleUpdateResult}>
            Update
          </Button>
          <Button variant="light" onClick={() => toggleResult("")}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAbonded}
        onHide={() => toggleAbonded("")}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <span>Match Abond</span>
          <Button variant="light btn-sm" onClick={() => toggleAbonded("")}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="fs-md">
          <p className="fs-lg">
            Are you sure want to update <strong>Abonded</strong>?
          </p>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button className="ms-2" onClick={handleUpdateAbd}>
            Update
          </Button>
          <Button variant="light" onClick={() => toggleAbonded("")}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <MarketPositionModal
        marketName={marketName}
        marketIds={marketId}
        show={showBook}
        clickHandler={toggleBook}
      />

      <BetListModal
        key={"match_id"}
        matchName={marketName}
        name="market_id"
        matchedId={matchedId}
        marketid={marketId}
        show={showBet}
        clickHandler={toggleBet}
      />

      <Modal
        show={showNews}
        onHide={() => toggleNews("")}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <span>Update News</span>
          <Button variant="light btn-sm" onClick={() => toggleNews("")}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="fs-md">
          <div className="form-floating">
            <Form.Control
              onChange={(e) => setNewsValues(e.target.value)}
              value={newsValues}
              as="textarea"
              rows={5}
              id="Content"
              style={{ height: "auto" }}
              placeholder="Content"
            />
            <Form.Label htmlFor="Content">Content</Form.Label>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button variant="light" onClick={() => toggleNews("")}>
            Cancel
          </Button>
          <Button className="ms-2" onClick={handleUpdateNews}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MatchResultTable;
