import ToggleSwitch from 'Common/ToggleSwitch';
import { useEffect, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { authServices } from 'Utils/auth/services';

interface MarketPositionModalProps {
	show: any;
	clickHandler: any;
    marketIds:string,
    marketName:string
}

const MarketPositionModal = ({show, clickHandler, marketIds, marketName} : MarketPositionModalProps) => {
    const[showMarket, setMarket] = useState(true)
    const[showBack, setBack] = useState(false)

    const toggleMarket = () => {
        setMarket(!showMarket)
    }

    const [showOn, setShowOn] = useState(false);
    const [bookData, setBookData] = useState<any>()
    const [BookFancyData, setBookFancyData] = useState<any>()

    const userId = localStorage.getItem("userId")

    const getMarketPosition = async () => {
        const { response } = await authServices.getMarketPosition({
            user_id: userId || "",
            market_id: marketIds
        })
        setBookData(response)
    }

    useEffect(() => {
        if(marketIds){
            getMarketPosition();
        }
    }, [userId, marketIds])


    const normalizeTeamName = (team: string): string => {
        return team.toLowerCase().replace(/ /g, "_");
    };

    useEffect(() => {
        if (!bookData?.data) return;

        const mergedData = bookData.data.users.map((user: any) => {
            const mergedUser = { ...user };

            bookData.data.metadata.teams.forEach((team: string) => {
                const normalizedTeam = normalizeTeamName(team);
                const teamScore = user[normalizedTeam];
                const teamExposure = user[`${normalizedTeam}_total_exposure`];

                mergedUser[team] = {
                    win_loss: teamScore || 0,
                    total_exposure: teamExposure || 0
                };

                delete mergedUser[normalizedTeam];
                delete mergedUser[`${normalizedTeam}_total_exposure`];
            });

            return mergedUser;
        });

        setBookFancyData(mergedData)
    }, [bookData]);


    return (
        <Modal size='lg' show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <div>Market Position: <span className='text-muted fw-normal'>({marketName})</span></div>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
                <div className='d-flex align-items-center'>
                    {showBack ? <span onClick={()=>setBack(false)} className='fs-lg ri-arrow-left-line me-2 cursor-pointer'></span> : null}
                    <strong className='me-3'>Position of {BookFancyData && BookFancyData[0]?.domain_name}</strong>
                    <ToggleSwitch On='S' Off='F' onChange={toggleMarket} checked={showMarket ? true : false} />
                </div>
                <div className='table-responsive'>
                <Table className="align-middle table-striped table-nowrap mb-0">
                        <thead>
                            <tr>
                                <th scope="col">Domain Name</th>
                                <th scope="col">Account</th>
                                {
                                    bookData?.data?.metadata?.teams?.map((items:any) => {
                                        return (
                                            <th scope="col">{items}</th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                BookFancyData?.map((user: any, id: number) => {
                                    return (
                                        <tr key={id}>
                                            <td>{user?.domain_name}</td>
                                            <td>
                                                <Button variant='subtle-primary' className='rounded-pill p-1' style={{ width: '30px' }}>
                                                    {user?.user_type_id === "1" ? "U" : "A"}
                                                </Button>
                                                <span className='text-primary ms-2'>{user?.user_name}</span>
                                            </td>

                                           
                                            {bookData?.data?.metadata?.teams?.map((team: string, index: number) => {
                                                const teamData = user[team];
                                                return (
                                                    <td key={index} className={teamData?.win_loss > 0 ? "text-success" : "text-danger"}>
                                                        {teamData?.win_loss}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })
                            }

                            <tr>
                                <td></td>
                                <td className='fw-bold'>Total</td>
                                {
                                    bookData?.data?.metadata?.total?.map((total:any) => {
                                        return (
                                            <td className={total?.win_loss > 0 ? 'text-success' : "text-danger"}>{total?.win_loss}</td>
                                        )
                                    })
                                }

                            </tr>
                            {
                                showMarket && <tr>
                                <td></td>
                                <td className='fw-bold'>Own</td>
                                {
                                    bookData?.data?.metadata?.own?.map((total:any) => {
                                        return (
                                            <td className={total?.win_loss > 0 ? 'text-success' : "text-danger"}>{total?.win_loss}</td>
                                        )
                                    })
                                }
                            </tr>
                            }
                            
                        </tbody>
                    </Table>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default MarketPositionModal;