import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';

interface BreadCrumbProps {
    title : string;
    pageTitle : string;
    back? : boolean;
}
const BreadCrumb = ({ title, pageTitle, back } : BreadCrumbProps) => {
    const navigate = useNavigate()
    const handleBack = () => {
        navigate(-1);
      };
    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <div className="page-title-box d-sm-flex align-items-center">
                        <div className='d-flex align-items-center'>
                            {back ? <Button variant='sm' className='py-0' onClick={handleBack}><i className='fs-xl align-middle ri-arrow-left-line'></i></Button> : null}
                            <h4 className="mb-0">{title}</h4>
                        </div>

                        {/* <div className="page-title-right d-none d-sm-block ms-3">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to="#">{pageTitle}</Link></li>
                                <li className="breadcrumb-item active">{title}</li>
                            </ol>
                        </div> */}

                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default BreadCrumb;