import ToggleSwitch from 'Common/ToggleSwitch';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Pagination, Row, Table } from 'react-bootstrap';
import Select from "react-select";
import { authServices } from 'Utils/auth/services';

interface BetListModalProps {
    show: any;
    clickHandler: any;
    matchedId: string;
    marketid: string;
    name:string;
    matchName:string
    key:string
}

const options:any = [];

const BetListModal = ({ show, clickHandler, matchedId, marketid, name:nameMekt, matchName }: BetListModalProps) => {
    const [betData, setBetData] = useState<betResponse[]>([]);
    const [name, setName] = useState('');
    const [domain, setDomain] = useState(null);
    const [userName, setUserName] = useState('');
    const [selection, setSelection] = useState('');
    const [betType, setBetType] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);

    const limit = 50; 

    const getBetsData = async (page: number = 1) => {
       
        const searchPayload: any = {
            bet_result_id: { $eq: null },
            [nameMekt]: marketid,
            match_id: matchedId,
        };
    
        if (userName) {
            searchPayload.user_name = userName;
        }
        if (selection) {
            searchPayload.odds = selection;
        }
        if (betType) {
            searchPayload.stack = betType;
        }
    
       
        const { response } = await authServices.getBets({
            match_id: matchedId,
            limit: limit,
            page: page,
            search: searchPayload,
        });
    
        setBetData(response?.data?.data);
        setTotalItems(response?.data?.totalItems || 0);
    };

    useEffect(() => {
        if (show) {
            getBetsData(currentPage);
        }
    }, [matchedId, marketid, currentPage]);

    const clearValues = () => {
        setName('');
        setDomain(null);
        setUserName('');
        setSelection('');
        setBetType('');
    };

    const handleModalClose = () => {
        clearValues(); 
        clickHandler(); 
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        getBetsData(page);
    };

    const totalPages = Math.ceil(totalItems / limit);

    return (
        <Modal size="xl" show={show} onHide={handleModalClose} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <div>Bet List - <span className="text-muted fw-normal">({matchName})</span></div>
                <Button variant="light btn-sm" onClick={handleModalClose}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className="fs-md">
                <Form>
                    <Row className="gy-3 align-items-end">
                        <Col lg={10}>
                            <Row className="gap-3 gap-sm-0">
                                <Col>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Domain</Form.Label>
                                    <Select
                                        isClearable
                                        options={options}
                                        className="customSelect"
                                        value={domain}
                                        onChange={setDomain}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>User Name</Form.Label>
                                    <Form.Control
                                        name="user_name"
                                        type="text"
                                        placeholder="Enter Username"
                                        value={userName}
                                        onChange={(e) => setUserName(e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Selection</Form.Label>
                                    <Form.Control
                                        name="odds"
                                        type="number"
                                        placeholder="Rate"
                                        value={selection}
                                        onChange={(e) => setSelection(e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label>Bet Type</Form.Label>
                                    <Form.Control
                                        name="stack"
                                        type="number"
                                        placeholder="Stack"
                                        value={betType}
                                        onChange={(e) => setBetType(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={2}>
                            <Button className="me-2 btn-sm" style={{ height: "38px" }} onClick={() => getBetsData(currentPage)}>
                                <i className="fs-lg align-middle ri-filter-line"></i> Submit
                            </Button>
                            <Button
                                variant="outline-primary"
                                className="me-2 btn-sm"
                                style={{ height: "38px" }}
                                onClick={clearValues}
                            >
                                <i className="fs-lg align-middle ri-refresh-line"></i> Clear
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <div className="table-responsive">
                    <Table className="mt-3 mb-0 table-centered align-middle table-nowrap table-striped">
                        <thead className="table-light">
                            <tr>
                                <th>No.</th>
                                <th>ID</th>
                                <th>Domain</th>
                                <th>User Name</th>
                                <th>Selection</th>
                                <th>Bet Type</th>
                                <th>Rate</th>
                                <th>Stack</th>
                                <th>P|L</th>
                                <th>Placed Time</th>
                                <th>BetID</th>
                                <th>IP Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            {betData?.map((items, id: number) => (
                                <tr key={id}>
                                    <td>{(currentPage - 1) * limit + id + 1}</td>
                                    <td>{items.bet_id}</td>
                                    <td>{items.domain_name}</td>
                                    <td>{items.user_name}</td>
                                    <td>{items.selection_name}</td>
                                    <td>{items.is_back === 0 ? "Lay" : "Back"}</td>
                                    <td>{items.odds}</td>
                                    <td>{items.stack}</td>
                                    <td>{items.p_l}</td>
                                    <td>{moment(items.createdAt).format("DD-MM-YYYY, hh:mm:ss A")}</td>
                                    <td>{items.bet_id}</td>
                                    <td>{items.ip_address}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

                <nav aria-label="Page navigation" className="mt-3 d-flex">
                    <Pagination className="ms-auto mb-0">
                        <Pagination.Item
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
                            <i className="mdi mdi-chevron-left"></i>
                        </Pagination.Item>
                        {[...Array(totalPages)].map((_, pageIndex) => (
                            <Pagination.Item
                                key={pageIndex}
                                active={currentPage === pageIndex + 1}
                                onClick={() => handlePageChange(pageIndex + 1)}
                            >
                                {pageIndex + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Item
                            disabled={currentPage === totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
                            <i className="mdi mdi-chevron-right"></i>
                        </Pagination.Item>
                    </Pagination>
                </nav>
            </Modal.Body>
        </Modal>
    );
};

export default BetListModal;
