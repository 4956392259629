import TableContainer from "Common/TableContainer";
import { FC, useMemo, useState, } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";

interface Props {
  bannerData: bannerPayload[]
  getDeleted: (id: string) => Promise<void>
}

const BannerSettingTable: FC<Props> = ({ bannerData, getDeleted }) => {
  const [showBanner, setBanner] = useState(false);
  const [showDelete, setDelete] = useState(false);
  const [deletedId, setDeletedId] = useState("");

  const toggleEditBanner = () => {
    setBanner(!showBanner);
  };
  const toggleDelete = (id:string) => {
    setDeletedId(id)
    setDelete(!showDelete);
  };


  const handleDeleted = ()=>{
    getDeleted(deletedId);
  }

 

  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "no",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return cell?.row?.index + 1
        },
      },
      {
        header: "Title",
        accessorKey: "title",
        enableColumnFilter: false,
      },
      {
        header: "Website",
        accessorKey: "website",
        enableColumnFilter: false,
      },
      {
        header: "Image",
        accessorKey: "image",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const url = `${process.env.REACT_APP_API_BASEURL}/${cell?.row?.original?.content}`
          return (
            <div>
              <img src={url} alt="Image" style={{ maxWidth: '100px' }} />
            </div>
          );
        },
      },
      {
        header: "Action",
        accessorKey: "action",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <Button className="btn-sm" variant="subtle-info" onClick={toggleEditBanner}><i className="fs-lg ri-edit-2-fill"></i></Button>
              <Button className="btn-sm ms-2" variant="subtle-danger" onClick={()=>toggleDelete(cell?.row?.original?._id)}><i className="fs-lg ri-delete-bin-6-fill"></i></Button>
            </div>
          )
        },
      },
    ],
    []
  );

  return (
    <>
      <TableContainer
        columns={columns || []}
        data={bannerData || []}
        isBordered={false}
        customPageSize={50}
        isPagination
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
      />

      <Modal show={showBanner} onHide={toggleEditBanner} className="zoomIn" scrollable>
        <Modal.Header className="modal-title fw-bold pb-2">
          <div>Upload Banner</div>
          <Button variant="light btn-sm" onClick={toggleEditBanner}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="pt-1">
          <div>
            <Form.Label>Type</Form.Label>
            <Select
              isClearable
              className="customSelect"
            />
          </div>
          <div className="mt-2">
            <Form.Label>Title</Form.Label>
            <Form.Control type="text" />
          </div>
          <div className="mt-2">
            <Form.Label>Website</Form.Label>
            <Form.Control type="text" />
          </div>
          <div className="mt-2">
            <Form.Label>Image</Form.Label>
            <Form.Control type="file" />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button>Update</Button>
          <Button variant="subtle-dark" onClick={toggleEditBanner}>Cancel</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDelete} onHide={()=>toggleDelete("")} className="zoomIn" scrollable>
        <Modal.Header className="modal-title fw-bold">
          Delete Banner
          <Button variant="light btn-sm" onClick={()=>toggleDelete("")}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <h4>Are you sure you want to Delete Banner?</h4>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button variant="danger" onClick={handleDeleted}>Delete</Button>
          <Button variant="subtle-dark" onClick={()=>toggleDelete("")}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BannerSettingTable;
