import { useEffect, useState } from "react";
import { Nav, Tab, Table } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import { useParams } from "react-router-dom";

interface NetExposureProps {
  filter?: boolean;
  activeTab: string;
  isReport?: boolean;
}

const NetExposure = ({ activeTab }: NetExposureProps) => {
  const { userid } = useParams();
  const [sportsData, setSportsData] = useState<SportData[]>([]);
  const [casinoData, setCasinoData] = useState<any[]>([]);
  const [fullExp, setFullExc] = useState<boolean>(true);

  const fetchExposureSport = async () => {
    try {
      const { response } = await authServices.getExposureSport({
        user_id: userid || "",
      });
      const filteredData = response.data.filter(
        (item: SportData) => !("liabilitySum" in item)
      );
      setSportsData(filteredData);
    } catch (error) {
      console.error("Error fetching exposure sport data:", error);
    }
  };
  const fetchExposureSportV1 = async () => {
    try {
      const { response } = await authServices.getExposureSportV1({
        user_id: userid || "",
      });
      const filteredData = response.data.filter(
        (item: SportData) => !("liabilitySum" in item)
      );
      setSportsData(filteredData);
    } catch (error) {
      console.error("Error fetching exposure sport data:", error);
    }
  };
  const fetchExposureCasino = async () => {
    try {
      const { response } = await authServices.getExposureCasino({
        user_id: userid || "",
      });
      setCasinoData(response.data);
    } catch (error) {
      console.error("Error fetching exposure casino data:", error);
    }
  };

  useEffect(() => {
    if (activeTab === "NetExposure") {
      if (fullExp) {
        fetchExposureSport();
      } else {
        fetchExposureSportV1();
      }
    }
  }, [activeTab, fullExp]);

  const renderTableRows = (data: SportData[]) =>
    data.map((item, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{item.sport_name}</td>
        <td>{item.match_name}</td>
        <td>{item.event_name}</td>
        <td style={{ color: item.liability < 0 ? "red" : "green" }}>
          {item.liability}
        </td>
      </tr>
    ));

  return (
    <>
      <Tab.Container defaultActiveKey="sports">
        <Nav
          as="ul"
          variant="tabs"
          className="nav-tabs-custom nav-primary nav-justified mb-3"
        >
          <Nav.Item as="li">
            <Nav.Link eventKey="sports" onClick={fetchExposureSport}>
              Sports
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link eventKey="casino" onClick={fetchExposureCasino}>
              Casino
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content className="text-muted">
          <Tab.Pane eventKey="sports">
            <div className="table-responsive">
              <Table className="table-striped table-nowrap align-middle mb-0">
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Sport Name</th>
                    <th>Event Name</th>
                    <th>Market</th>
                    <th>Exposure</th>
                  </tr>
                </thead>
                <tbody>{renderTableRows(sportsData)}</tbody>
              </Table>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="casino">
            {casinoData?.length === 0 ? (
              <div
                style={{
                  fontSize: "14",
                  textAlign: "center",
                }}
              >
                No Data Found
              </div>
            ) : (
              <div className="table-responsive">
                <Table className="table-striped table-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Sport Name</th>
                      <th>Event Name</th>
                      <th>Market</th>
                      <th>Exposure</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Example rows - Replace with actual data */}
                    <tr>
                      <td>01</td>
                      <td>Horse Racing</td>
                      <td>Seymour (AUS) 27th Aug</td>
                      <td>To Be Placed</td>
                      <td>
                        <span className="text-danger">-200</span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default NetExposure;
