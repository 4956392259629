import BreadCrumb from "Common/BreadCrumb";
import { Container, Tab, Nav, Button } from "react-bootstrap";
import Statement from "./Statement";
import ChipSummary from "./ChipSummary";
import CasinoBets from "./CasinoBets";
import ProfitLoss from "./ProfitLoss";
import { useEffect, useState } from "react";
import NewsUpdate from "./NewsUpdate";
import OpenBets from "./OpenBets";
import SettledBets from "./SettledBets";
import SportsPL from "./SportsPL";
import { useNavigationType, useParams } from "react-router-dom";
import FancyPL from "./FancyPL";
import FancyStakeUserWise from "./FancyStackUserWise";

const Reports = () => {
  const { nav } = useParams();
  const [showFilter, setFilter] = useState(true);
  const [showHeading, setHeading] = useState("");
  const [showFilteBtn, setFilterBtn] = useState(true);
  const [activeTab, setActiveTab] = useState<string>(
    localStorage.getItem("activeTab") || "Statement"
  );

  const navigationType = useNavigationType();

  useEffect(() => {
    if (navigationType !== "POP" && nav) {
      setActiveTab(nav);
    }
  }, [nav, navigationType]);

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  const handleNavClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const innerText = event.currentTarget.innerText;
    setHeading(innerText);
    setActiveTab(
      event.currentTarget.getAttribute("data-rr-ui-event-key") || "Statement"
    );

    if (innerText === "News Update") {
      setFilterBtn(false);
    } else {
      setFilterBtn(true);
    }
  };
  return (
    <div className="page-content">
      <Container fluid>
        <div
          className="position-relative mb-2 d-flex justify-content-between align-items-center"
          style={{ minHeight: "43px" }}
        >
          <BreadCrumb
            title={showHeading ? showHeading : "Reports"}
            pageTitle="Dashboard"
            back
          />
          {showFilteBtn ? (
            <Button
              variant="subtle-dark"
              onClick={() => setFilter(!showFilter)}
            >
              <i className="fs-lg align-middle ri-filter-3-line"></i> Filter
            </Button>
          ) : null}
        </div>

        <Tab.Container
          defaultActiveKey={activeTab || "Statement"}
          activeKey={activeTab}
        >
          <Nav
            as="ul"
            variant="pills"
            className="arrow-navtabs nav-secondary mb-3 flex-nowrap overflow-auto text-nowrap"
            style={{ height: "48px" }}
          >
            <Nav.Item as="li" className="me-2">
              <Nav.Link eventKey="Statement" onClick={handleNavClick}>
                Statement{" "}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="mx-2">
              <Nav.Link eventKey="ChipSummary" onClick={handleNavClick}>
                Chip Summary{" "}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="mx-2">
              <Nav.Link eventKey="CasinoBets" onClick={handleNavClick}>
                Casino Bets{" "}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="mx-2">
              <Nav.Link eventKey="Market" onClick={handleNavClick}>
                Profit Loss{" "}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="mx-2">
              <Nav.Link eventKey="OpenBets" onClick={handleNavClick}>
                Open Bets{" "}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="mx-2">
              <Nav.Link eventKey="SettledBets" onClick={handleNavClick}>
                Settled Bets{" "}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="mx-2">
              <Nav.Link eventKey="NewsUpdate" onClick={handleNavClick}>
                News Update{" "}
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="text-muted">
            <Tab.Pane eventKey="Statement">
              <Statement filter={showFilter ? true : false} activeTab={activeTab} />
            </Tab.Pane>
            <Tab.Pane eventKey="ChipSummary">
              <ChipSummary activeTab={activeTab} />
            </Tab.Pane>
            <Tab.Pane eventKey="CasinoBets">
              <CasinoBets filter={showFilter ? true : false} activeTab={activeTab} />
            </Tab.Pane>
            <Tab.Pane eventKey="Market">
              <ProfitLoss filter={showFilter ? true : false} activeTab={activeTab} />
            </Tab.Pane>
            <Tab.Pane eventKey="OpenBets">
              <OpenBets filter={showFilter ? true : false} activeTab={activeTab} />
            </Tab.Pane>
            <Tab.Pane eventKey="SettledBets">
              <SettledBets filter={showFilter ? true : false} activeTab={activeTab} />
            </Tab.Pane>
            <Tab.Pane eventKey="NewsUpdate">
              <NewsUpdate activeTab={activeTab} />
            </Tab.Pane>
            <Tab.Pane eventKey="SportsPL">
              <SportsPL filter={showFilter ? true : false} isCasino={false} activeTab={activeTab} />
            </Tab.Pane>
            <Tab.Pane eventKey="QTechPL">
              <SportsPL filter={showFilter ? true : false} isCasino={true} activeTab={activeTab} />
            </Tab.Pane>
            <Tab.Pane eventKey="FancyPL">
              <FancyPL filter={showFilter ? true : false} activeTab={activeTab} isPL={true} />
            </Tab.Pane>
            <Tab.Pane eventKey="FancyStakeM">
              <FancyPL filter={showFilter ? true : false} activeTab={activeTab} isPL={false} />
            </Tab.Pane>
            <Tab.Pane eventKey="FancyStakeU">
              <FancyStakeUserWise filter={showFilter ? true : false} activeTab={activeTab} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
    </div>
  );
};

export default Reports;
