import { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import ToggleSwitch from 'Common/ToggleSwitch';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';

interface MatchSettingProps {
    show: boolean;
    clickHandler: any;
    limits: any;
    id?: string | undefined;
    getLimit: any,
    fancyShow?: boolean
    name?: string,
    shoeOdss?: boolean,
    marketId?: string,
    sportIds: string
    marketName: string,
    seriesIdss: string | undefined
}

const LimitSetting = ({ show, clickHandler, limits, getLimit, name, marketId, sportIds, marketName, seriesIdss }: MatchSettingProps) => {
    const [formData, setFormData] = useState<any>({
        market_min_stack: "",
        market_max_stack: "",
        market_min_odds_rate: "",
        market_max_odds_rate: "",
        market_max_profit: "",
        market_advance_bet_stake: "",
        min_volume_limit: "",
        inplay_max_volume_stake_0_10: "",
        inplay_max_volume_stake_10_40: "",
        inplay_max_volume_stake_40: "",
        max_volume_stake_0_10: "",
        max_volume_stake_10_40: "",
        max_volume_stake_40: "",
        session_min_stack: "",
        session_max_stack: "",
        session_max_profit: "",
    });

    const [errors, setErrors] = useState<any>({});

    useEffect(() => {
        if (limits) {
            setFormData({
                market_min_stack: limits?.limites?.market_min_stack || "",
                market_max_stack: limits?.limites?.market_max_stack || "",
                market_min_odds_rate: limits?.limites?.market_min_odds_rate || "",
                market_max_odds_rate: limits?.limites?.market_max_odds_rate || "",
                market_max_profit: limits?.limites?.market_max_profit || "",
                market_advance_bet_stake: limits?.limites?.market_advance_bet_stake || "",
                min_volume_limit: limits?.limites?.min_volume_limit || "",
                inplay_max_volume_stake_0_10: limits?.limites?.inplay_max_volume_stake_0_10 || "",
                inplay_max_volume_stake_10_40: limits?.limites?.inplay_max_volume_stake_10_40 || "",
                inplay_max_volume_stake_40: limits?.limites?.inplay_max_volume_stake_40 || "",
                max_volume_stake_0_10: limits?.limites?.max_volume_stake_0_10 || "",
                max_volume_stake_10_40: limits?.limites?.max_volume_stake_10_40 || "",
                max_volume_stake_40: limits?.limites?.max_volume_stake_40 || "",
                session_min_stack: limits?.limites?.session_min_stack || "",
                session_max_stack: limits?.limites?.session_max_stack || "",
                session_max_profit: limits?.limites?.session_max_profit || "",
            });
        }
    }, [limits]);

    const [advanceMarket, setAdvanceMarket] = useState(true);

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        // Update form data
        setFormData({
            ...formData,
            [name]: value,
        });

        // Check if value is empty
        if (value === "") {
            setErrors((prevErrors: any) => ({
                ...prevErrors,
                [name]: 'This field is required.'
            }));
            return; // Stop further validation for empty fields
        } else {
            // Clear required error if there's a valid value
            setErrors((prevErrors: any) => ({
                ...prevErrors,
                [name]: ''
            }));
        }

        // Perform min/max validation only for specific fields
        if (name.includes('stack') || name.includes('stake') || name.includes('profit')) {
            const [fieldPrefix, range] = name.split('_');

            // For minimum fields
            if (range === 'min') {
                const maxFieldName = `${fieldPrefix}_max_${name.split('_')[2]}`;
                if (parseFloat(value) > parseFloat(formData[maxFieldName]) && formData[maxFieldName]) {
                    setErrors((prevErrors: any) => ({
                        ...prevErrors,
                        [name]: 'Minimum value cannot be greater than maximum value.'
                    }));
                } else {
                    setErrors((prevErrors: any) => ({
                        ...prevErrors,
                        [name]: ''
                    }));
                }
            }

            // For maximum fields
            else if (range === 'max') {
                const minFieldName = `${fieldPrefix}_min_${name.split('_')[2]}`; // Get corresponding min field
                if (parseFloat(value) < parseFloat(formData[minFieldName]) && formData[minFieldName]) {
                    setErrors((prevErrors: any) => ({
                        ...prevErrors,
                        [name]: 'Maximum value cannot be less than minimum value.'
                    }));
                } else {
                    setErrors((prevErrors: any) => ({
                        ...prevErrors,
                        [name]: ''
                    }));
                }
            }
        }
    };

    console.log(name,sportIds, "sportIdssportIdssportIds" )

    const getUpdateLimits = async (data: any) => {
        let payload;
        if (sportIds === "4" && name !== "Match Odds") {
            payload = data;
        } else if (sportIds === "4" &&  name !== "Match Odds") {
            payload = {
                session_min_stack: data.session_min_stack || 0,
                session_max_stack: data.session_max_stack || 0,
                session_max_profit: data.session_max_profit || 0,
            };
        } else if (name === "Match Odds") {
            payload = {
                market_min_stack: data.market_min_stack,
                market_max_stack: data.market_max_stack,
                market_min_odds_rate: data.market_min_odds_rate,
                market_max_odds_rate: data.market_max_odds_rate,
                market_max_profit: data.market_max_profit,
                market_advance_bet_stake: data.market_advance_bet_stake,
                min_volume_limit: data.min_volume_limit,
                inplay_max_volume_stake_0_10: data.inplay_max_volume_stake_0_10,
                inplay_max_volume_stake_10_40: data.inplay_max_volume_stake_10_40,
                inplay_max_volume_stake_40: data.inplay_max_volume_stake_40,
                max_volume_stake_0_10: data.max_volume_stake_0_10,
                max_volume_stake_10_40: data.max_volume_stake_10_40,
                max_volume_stake_40: data.max_volume_stake_40,
            };
        }else{
            payload = {
                market_min_stack: data.market_min_stack,
                market_max_stack: data.market_max_stack,
                market_min_odds_rate: data.market_min_odds_rate,
                market_max_odds_rate: data.market_max_odds_rate,
                market_max_profit: data.market_max_profit,
                market_advance_bet_stake: data.market_advance_bet_stake,
                min_volume_limit: data.min_volume_limit,
                inplay_max_volume_stake_0_10: data.inplay_max_volume_stake_0_10,
                inplay_max_volume_stake_10_40: data.inplay_max_volume_stake_10_40,
                inplay_max_volume_stake_40: data.inplay_max_volume_stake_40,
                max_volume_stake_0_10: data.max_volume_stake_0_10,
                max_volume_stake_10_40: data.max_volume_stake_10_40,
                max_volume_stake_40: data.max_volume_stake_40,
            };
        }
        const { response } = await authServices.updateLimits({
            [marketName]: seriesIdss || "",
            values: payload,
        });
        if (response) {
            if (response.status) {
                snackbarUtil.success(response.msg);
                clickHandler();
            } else {
                snackbarUtil.error(response.msg);
            }
        }
    };

    const handleSave = () => {
        if (Object.values(errors).some((error: any) => error)) {
            snackbarUtil.error("Please fix the errors before saving.");
            return;
        }
        getUpdateLimits(formData);
    };

    const handleClose = () => {
        clickHandler();
    };

    return (
        <Modal size="xl" show={show} onHide={handleClose} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-3 pb-md-0">
                <div>{name}</div>
                <Button variant="light btn-sm" onClick={handleClose}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row className="g-3">
                        <h5 className='text-primary mb-0'>Market Setting</h5>
                        <Col lg={3}>
                            <div className="form-floating">
                                <Form.Control
                                    name="market_min_stack"
                                    type="number"
                                    id="MinStakeAmount"
                                    placeholder="Min. Stake Amount"
                                    value={formData.market_min_stack}
                                    onChange={handleInputChange}
                                />
                                <Form.Label htmlFor="MinStakeAmount">Min. Stake Amount</Form.Label>
                                {errors.market_min_stack && <div className="text-danger">{errors.market_min_stack}</div>}
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-floating">
                                <Form.Control
                                    name="market_max_stack"
                                    type="number"
                                    id="MaxStakeAmount"
                                    placeholder="Max. Stake Amount"
                                    value={formData.market_max_stack}
                                    onChange={handleInputChange}
                                />
                                <Form.Label htmlFor="MaxStakeAmount">Max. Stake Amount</Form.Label>
                                {errors.market_max_stack && <div className="text-danger">{errors.market_max_stack}</div>}
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-floating">
                                <Form.Control
                                    name="market_min_odds_rate"
                                    type="number"
                                    id="MinOddsRate"
                                    placeholder="Min. Odds Rate"
                                    value={formData.market_min_odds_rate}
                                    onChange={handleInputChange}
                                />
                                <Form.Label htmlFor="MinOddsRate">Min. Odds Rate</Form.Label>
                                {errors.market_min_odds_rate && <div className="text-danger">{errors.market_min_odds_rate}</div>}
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-floating">
                                <Form.Control
                                    name="market_max_odds_rate"
                                    type="number"
                                    id="MaxOddsRate"
                                    placeholder="Max. Odds Rate"
                                    value={formData.market_max_odds_rate}
                                    onChange={handleInputChange}
                                />
                                <Form.Label htmlFor="MaxOddsRate">Max. Odds Rate</Form.Label>
                                {errors.market_max_odds_rate && <div className="text-danger">{errors.market_max_odds_rate}</div>}
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-floating">
                                <Form.Control
                                    name="market_max_profit"
                                    type="number"
                                    id="MarketMaxProfit"
                                    placeholder="Market Max Profit"
                                    value={formData.market_max_profit}
                                    onChange={handleInputChange}
                                />
                                <Form.Label htmlFor="MarketMaxProfit">Market Max Profit</Form.Label>
                                {errors.market_max_profit && <div className="text-danger">{errors.market_max_profit}</div>}
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-floating">
                                <Form.Control
                                    name="market_advance_bet_stake"
                                    type="number"
                                    id="InplayMatchStake"
                                    placeholder="Before Inplay Match Stake"
                                    value={formData.market_advance_bet_stake}
                                    onChange={handleInputChange}
                                />
                                <Form.Label htmlFor="InplayMatchStake">Before Inplay Match Stake</Form.Label>
                                {errors.market_advance_bet_stake && <div className="text-danger">{errors.market_advance_bet_stake}</div>}
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-floating">
                                <Form.Control
                                    name="min_volume_limit"
                                    type="number"
                                    id="MinVolumeStake"
                                    placeholder="Min Volume Stake"
                                    value={formData.min_volume_limit}
                                    onChange={handleInputChange}
                                />
                                <Form.Label htmlFor="MinVolumeStake">Min Volume Stake</Form.Label>
                                {errors.min_volume_limit && <div className="text-danger">{errors.min_volume_limit}</div>}
                            </div>
                        </Col>

                        <div className='d-flex'>
                            <h5 className='text-primary mb-0'>Advance Market Stake Setting</h5>
                            <ToggleSwitch On='On' Off='Off' className='ms-2' onChange={() => setAdvanceMarket(!advanceMarket)} />
                        </div>

                        <Col lg={3}>
                            <div className="form-floating">
                                <Form.Control
                                    name="inplay_max_volume_stake_0_10"
                                    type="number"
                                    placeholder="Max Stake (0-10k gbp)"
                                    value={formData.inplay_max_volume_stake_0_10}
                                    onChange={handleInputChange}
                                />
                                <Form.Label>Max Stake (0-10k gbp)</Form.Label>
                                {errors.inplay_max_volume_stake_0_10 && <div className="text-danger">{errors.inplay_max_volume_stake_0_10}</div>}
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-floating">
                                <Form.Control
                                    name="inplay_max_volume_stake_10_40"
                                    type="number"
                                    placeholder="Max Stake (10k-40k gbp)"
                                    value={formData.inplay_max_volume_stake_10_40}
                                    onChange={handleInputChange}
                                />
                                <Form.Label>Max Stake (10k-40k gbp)</Form.Label>
                                {errors.inplay_max_volume_stake_10_40 && <div className="text-danger">{errors.inplay_max_volume_stake_10_40}</div>}
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-floating">
                                <Form.Control
                                    name="inplay_max_volume_stake_40"
                                    type="number"
                                    placeholder="Max Stake (>40k gbp)"
                                    value={formData.inplay_max_volume_stake_40}
                                    onChange={handleInputChange}
                                />
                                <Form.Label>Max Stake ({'>40k gbp'})</Form.Label>
                                {errors.inplay_max_volume_stake_40 && <div className="text-danger">{errors.inplay_max_volume_stake_40}</div>}
                            </div>
                        </Col>

                        <h5 className='text-primary mb-0'>Market Stake Setting</h5>
                        <Col lg={3}>
                            <div className="form-floating">
                                <Form.Control
                                    name="max_volume_stake_0_10"
                                    type="number"
                                    placeholder="Max Stake (0-10k gbp)"
                                    value={formData.max_volume_stake_0_10}
                                    onChange={handleInputChange}
                                />
                                <Form.Label>Max Stake (0-10k gbp)</Form.Label>
                                {errors.max_volume_stake_0_10 && <div className="text-danger">{errors.max_volume_stake_0_10}</div>}
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-floating">
                                <Form.Control
                                    name="max_volume_stake_10_40"
                                    type="number"
                                    placeholder="Max Stake (10k-40k gbp)"
                                    value={formData.max_volume_stake_10_40}
                                    onChange={handleInputChange}
                                />
                                <Form.Label>Max Stake (10k-40k gbp)</Form.Label>
                                {errors.max_volume_stake_10_40 && <div className="text-danger">{errors.max_volume_stake_10_40}</div>}
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-floating">
                                <Form.Control
                                    name="max_volume_stake_40"
                                    type="number"
                                    placeholder="Max Stake (>40k gbp)"
                                    value={formData.max_volume_stake_40}
                                    onChange={handleInputChange}
                                />
                                <Form.Label>Max Stake ({'>40k gbp'})</Form.Label>
                                {errors.max_volume_stake_40 && <div className="text-danger">{errors.max_volume_stake_40}</div>}
                            </div>
                        </Col>


                        {
                            sportIds === "4" && name !== "Match Odds" && <>
                                <h5 className='text-primary mb-0'>Session Setting</h5>
                                <Col lg={3}>
                                    <div className="form-floating">
                                        <Form.Control
                                            name="session_min_stack"
                                            type="number"
                                            placeholder="Session Min Stake"
                                            value={formData.session_min_stack}
                                            onChange={handleInputChange}
                                        />
                                        <Form.Label>Session Min Stake</Form.Label>
                                        {errors.session_min_stack && <div className="text-danger">{errors.session_min_stack}</div>}
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="form-floating">
                                        <Form.Control
                                            name="session_max_stack"
                                            type="number"
                                            placeholder="Session Max Stake"
                                            value={formData.session_max_stack}
                                            onChange={handleInputChange}
                                        />
                                        <Form.Label>Session Max Stake</Form.Label>
                                        {errors.session_max_stack && <div className="text-danger">{errors.session_max_stack}</div>}
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="form-floating">
                                        <Form.Control
                                            name="session_max_profit"
                                            type="number"
                                            placeholder="Session Max Profit"
                                            value={formData.session_max_profit}
                                            onChange={handleInputChange}
                                        />
                                        <Form.Label>Session Max Profit</Form.Label>
                                        {errors.session_max_profit && <div className="text-danger">{errors.session_max_profit}</div>}
                                    </div>
                                </Col>
                            </>
                        }

                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {/* <div className='d-flex align-items-center'>
                    <ToggleSwitch label="Advance Market" isOn={advanceMarket} handleToggle={() => setAdvanceMarket(!advanceMarket)} />
                    <span className="ms-2">{advanceMarket ? "ON" : "OFF"}</span>
                </div> */}
                <Button variant="light" onClick={handleClose}>Cancel</Button>
                <Button variant="primary" onClick={handleSave}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LimitSetting;
