import BreadCrumb from "Common/BreadCrumb";
import { Container, Card, } from "react-bootstrap";

const Withdraw = () => {
    return (
        <div className="page-content">
            <Container fluid>
                <div className="position-relative mb-3 d-flex justify-content-between align-items-center">
                    <BreadCrumb title='Withdraw' pageTitle="Dashboard" back />
                </div>

                <Card>
                    <Card.Body>
                        <h5>Withdraw</h5>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
};

export default Withdraw;