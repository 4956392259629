import TableContainer from "Common/TableContainer";
import moment from "moment";
import { useMemo, useState } from "react";
import { Button, Modal, Pagination, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { authServices } from "Utils/auth/services";

interface SportsWisePLTableProps {
  sportsPLData: SportWisePlList[];
  sports: SportsList[];
  parentId: string;
  handleUserDetail: (value: string) => void;
  isEvent: boolean;
}

const SportsWisePLTable = ({
  sportsPLData,
  sports,
  parentId,
  handleUserDetail,
  isEvent,
}: SportsWisePLTableProps) => {
  const { matchid, marketid, type } = useParams();
  const navigate = useNavigate();
  const [showList, setList] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [agentList, setAgentList] = useState<CasinoUserAgent[]>([]);

  const toggleList = (name?: string) => {
    setAgentList([]);
    setList(!showList);
    setSelectedUser(name || "");
  };

  const getUserAgent = async (userId: string) => {
    const { response } = await authServices.getShowAgent({
      user_id: userId,
    });
    if (response?.data) {
      setAgentList(response?.data?.agents);
    }
  };

  return (
    <>
      <div className="table-responsive">
        <Table className="table-centered align-middle table-nowrap table-striped">
          <thead className="table-light">
            <tr>
              <th>S. No.</th>
              <th>Name</th>
              {sports.map((key) => (
                <th>{key.name}</th>
              ))}
              <th>
                View Bets
                {parentId && parentId != "null" && (
                  <Button
                    variant="subtle-dark"
                    className="btn-sm"
                    onClick={() => handleUserDetail(parentId)}
                  >
                    Back
                  </Button>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {sportsPLData?.map((item, index) => {
              return (
                <tr>
                  <td>{index < 9 ? `0${index + 1}` : index + 1}</td>
                  <td
                    className="text-info cursor-pointer"
                    onClick={() => {
                      item?.user_type_id != "1" &&
                        handleUserDetail(item?.user_id);
                    }}
                  >
                    <span className="badge bg-info-subtle text-info">{item?.user_type_id != "1" ? 'A' : 'C'}</span>{" "}
                    {item?.user_name} A/C
                  </td>
                  {sports.map((key) => (
                    <td>
                      <td
                        className={`text-${
                          Number(
                            item[key.lower_name as keyof SportWisePlList]
                          ) < 0
                            ? "danger"
                            : "success"
                        }`}
                      >
                        {Number(item[key.lower_name as keyof SportWisePlList]).toFixed(2)}
                      </td>
                    </td>
                  ))}
                  <td>
                    <Button
                      variant="subtle-success"
                      className="btn-sm ms-2"
                      onClick={() => {
                        toggleList(item?.user_name);
                        getUserAgent(item?.user_id);
                      }}
                    >
                      P
                    </Button>
                    {item?.user_type_id != "1" ? null : <Button
                      variant="subtle-dark"
                      className="btn-sm"
                      onClick={() =>
                        isEvent ? navigate(
                          `/view-bets/${matchid}/${marketid}/${type}/${item?.user_id}`
                        ) : navigate(
                            `/reports/Statement/${item?.user_id}`
                          )
                      }
                    >
                      {isEvent ? "View Bets" : "Statement"}
                    </Button>
            }
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={1}></th>
              <th>Total</th>
              {sports.map((key) => (
                <th className={`text-${
                    key?.total < 0 ? "danger" : "success"
                  }`}>{key?.total.toFixed(2)}</th>
              ))}
              
              <th
                colSpan={1}
              >
              </th>
            </tr>
          </tfoot>
        </Table>
      </div>

      <Modal
        show={showList}
        onHide={() => toggleList()}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center">
            Parent List of{" "}
            <span className="text-muted fw-normal mx-2">({selectedUser})</span>
          </div>
          <Button variant="light btn-sm" onClick={() => toggleList()}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        {agentList?.map((item) => {
          return (
            <Modal.Body>
              {item.name}({item.user_name})
            </Modal.Body>
          );
        })}
      </Modal>
    </>
  );
};

export default SportsWisePLTable;
