import { ListPagination } from "Common/ListPagination";
import TableEntry from "Common/TableEntry";
import moment from "moment";
import { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface ProfitLossTableProps {
  profitLossData: ProfitLossData[];
  page: number;
  limit: number;
  listTotal: ProfitLossTotal;
  profitLossSum: ProfitLossSum;
  handlePageFilter: (value: number, limit: number) => void;
  startDate: string;
  endDate: string;
}

const ProfitLossTable = ({
  profitLossData,
  page,
  limit,
  listTotal,
  profitLossSum,
  handlePageFilter,
  startDate,
  endDate,
}: ProfitLossTableProps) => {
  const navigate = useNavigate();
  const [showList, setList] = useState(false);
  const toggleList = () => {
    setList(!showList);
  };

  return (
    <>
      <div className="table-responsive">
        <Table className="table-centered align-middle table-nowrap table-striped">
          <thead className="table-light">
            <tr>
              <th>No.</th>
              <th>Date</th>
              <th>Sports | Series</th>
              <th>Market | Match</th>
              <th>P | L</th>
              <th>Commission</th>
              <th>Net PNL</th>
              <th>Result</th>
              <th>View Bets</th>
            </tr>
          </thead>
          <tbody>
            {profitLossData?.map((item, index) => {
              return (
                <tr>
                  <td>{((page - 1) * limit) + index + 1}</td>
                  <td>
                    <p className="mb-0">
                      {moment(item?.result_date).format("DD-MM-YY")}
                    </p>
                    <p className="mb-0">
                      {moment(item?.result_date).format(" hh:mm A")}
                    </p>
                  </td>
                  <td>
                    <strong>{item?.sport_name}</strong>
                    <p className="mb-0 text-muted">{item?.series_name}</p>
                  </td>
                  <td>
                    <strong>{item?.event_name}</strong>
                    <p className="mb-0 text-muted">{item?.match_name}</p>
                  </td>
                  <td
                    className={`text-${item?.p_l < 0 ? "danger" : "success"}`}
                  >
                    {item?.p_l}
                  </td>
                  <td
                    className={`text-${
                      item?.commission < 0 ? "danger" : "success"
                    }`}
                  >
                    {item?.commission.toFixed(2)}
                  </td>
                  <td
                    className={`text-${
                      item?.net_pl < 0 ? "danger" : "success"
                    }`}
                  >
                    {item?.net_pl.toFixed(2)}
                  </td>
                  <td>{item?.winner_name}</td>
                  <td>
                    <Button
                      variant="subtle-dark"
                      className="btn-sm"
                      onClick={() =>
                        navigate(
                          `/view-bets/${item.match_id}/${item.event_id}/${item.type}`
                        )
                      }
                    >
                      View Bets
                    </Button>
                    <Button
                      variant="subtle-primary"
                      className="btn-sm ms-2"
                      onClick={() =>
                        navigate(
                          `/sports-wise-pl/${item.match_id}/${item.event_id}/${item.type}/${startDate}/${endDate}`
                        )
                      }
                    >
                      P | L
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={3}></th>
              <th>Total</th>
              <th
                className={`text-${
                  profitLossSum?.p_l < 0 ? "danger" : "success"
                }`}
              >
                {profitLossSum?.p_l.toFixed(2) || 0}
              </th>
              <th
                className={`text-${
                  profitLossSum?.commission < 0 ? "danger" : "success"
                }`}
              >
                {profitLossSum?.commission.toFixed(2) || 0}
              </th>
              <th
                colSpan={3}
                className={`text-${
                  profitLossSum?.net_pl < 0 ? "danger" : "success"
                }`}
              >
                {profitLossSum?.net_pl.toFixed(2) || 0}
              </th>
            </tr>
          </tfoot>
        </Table>
      </div>
      <nav aria-label="Page navigation" className="px-3 d-flex flex-column flex-sm-row align-items-center">
          <TableEntry
            limit={limit}
            handlelimitFilter={(value: number) => handlePageFilter(page, value)}
          />
          <ListPagination
            listTotal={listTotal}
            handlePageFilter={(value: number) => handlePageFilter(value, limit)}
          />
        </nav>

      <Modal show={showList} onHide={toggleList} className="zoomIn" scrollable>
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center">
            Parent List of{" "}
            <span className="text-muted fw-normal mx-2">(lcbook247-prime)</span>
          </div>
          <Button variant="light btn-sm" onClick={toggleList}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>List</Modal.Body>
      </Modal>
    </>
  );
};

export default ProfitLossTable;
