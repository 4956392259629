import BreadCrumb from "Common/BreadCrumb";
import DomainSettingTable from "Common/Tables/DomainSettingTable";
import { useEffect, useState } from "react";
import { Container, Button, Card, Form, Row, Col, Alert } from "react-bootstrap";
import { settingServices } from "Utils/setting/services";
import snackbarUtil from "Utils/snackBarUtil";

const DomainSetting = () => {
    const [domianData, setDomainData] = useState<WebsiteResponse[]>([]);
    const [siteTitleChanged, setSiteTitleChanged] = useState("");
    const [siteData, setSiteData] = useState({
        domain_name: "",
        host_name: "",
        site_title: ""
    });
    const [errors, setErrors] = useState({
        domain_name: "",
        host_name: "",
        site_title: ""
    });
    const [checkError, setCheckError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getWebsiteList = async () => {
        const { response } = await settingServices.getWebisteData();
        setDomainData(response?.data);
    };

    const checkSiteData = async (site_title: string) => {
        const { response } = await settingServices.checkSiteData({ site_title });

        if (response?.msg?.includes("Site title data is already exists")) {
            setSiteTitleChanged(response?.msg);
           setErrors((prev)=>({
            ...prev,
            site_title: response?.msg
           }))
        }
    };

    useEffect(() => {
        getWebsiteList();
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setSiteData({
            ...siteData,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: ""
        });
        if (name === "site_title") {
            checkSiteData(value);
        }
    };

    const validateForm = () => {
        let formErrors = { domain_name: "", host_name: "", site_title: "" };
        let isValid = true;

        if (!siteData.domain_name) {
            formErrors.domain_name = "Domain name is required.";
            isValid = false;
        }
        if (!siteData.host_name) {
            formErrors.host_name = "Host name is required.";
            isValid = false;
        }
        if (!siteData.site_title) {
            formErrors.site_title = "Site title is required.";
            isValid = false;
        }
        if (siteTitleChanged?.includes("Site title data is already exists")) {
            formErrors.site_title = "Site title data is already exists";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };


    const createWebsite = async () => {
        if (validateForm()) {
            setIsSubmitting(true);
            if (!checkError) {
                const { response } = await settingServices.createWebsite(siteData);
                if (response?.status) {
                    getWebsiteList();
                    snackbarUtil?.success(response?.msg);
                    setSiteData({
                        domain_name: "",
                        host_name: "",
                        site_title: ""
                    })
                }else{
                    snackbarUtil?.error(response?.msg);
                }
            }
            setIsSubmitting(false);
        }
    };

    return (
        <div className="page-content">
            <Container fluid>
                <div className="position-relative mb-2 d-flex justify-content-between align-items-center">
                    <BreadCrumb title="Domain Setting" pageTitle="Dashboard" back />
                    
                </div>
                    <Card>
                        <Card.Body>
                            <Form>
                                <Row className="gy-3 align-items-end">
                                    <Col lg={9}>
                                        <Row className="gap-3 gap-sm-0">
                                            <Col lg={4}>
                                                <Form.Label>Website Name</Form.Label>
                                                <Form.Control
                                                    name="host_name"
                                                    type="text"
                                                    placeholder="Website"
                                                    value={siteData.host_name}
                                                    onChange={handleInputChange}
                                                    isInvalid={!!errors.host_name}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.host_name}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col lg={4}>
                                                <Form.Label>Site Title Data</Form.Label>
                                                <Form.Control
                                                    name="site_title"
                                                    type="text"
                                                    placeholder="Title"
                                                    value={siteData.site_title}
                                                    onChange={handleInputChange}
                                                    isInvalid={!!errors.site_title}
                                                    // onBlur={handleBlur}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.site_title}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col lg={4}>
                                                <Form.Label>Domain Name</Form.Label>
                                                <Form.Control
                                                    name="domain_name"
                                                    type="text"
                                                    placeholder="Domain"
                                                    value={siteData.domain_name}
                                                    onChange={handleInputChange}
                                                    isInvalid={!!errors.domain_name}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.domain_name}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={3}>
                                        <Button
                                            className="me-2 btn-sm"
                                            style={{ height: "38px" }}
                                            onClick={createWebsite}
                                            disabled={isSubmitting}
                                        >
                                            <i className="fs-lg align-middle ri-add-fill"></i> Add
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                            {checkError && <Alert variant="danger">{checkError}</Alert>}
                        </Card.Body>
                    </Card>
              

                <Card>
                    <Card.Body>
                        <DomainSettingTable domianData={domianData} getWebsiteList={getWebsiteList} />
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
};

export default DomainSetting;
