import { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';

interface ShowPasswordModalProps {
    show: any;
    clickHandler: any;
    userIds: string;
    userName:string
}

interface ResData {
    password: string;
    status: boolean;
    
}

const ShowPasswordModal = ({ show, clickHandler, userIds, userName }: ShowPasswordModalProps) => {
    const [showMainPassword, setMainPassword] = useState(false);
    const [showPassword, setPassword] = useState(false);
    const [passVal, setPasswordVal] = useState<string>("");
    const [passData, setPassData] = useState<ResData | null>(null);
    const [validated, setValidated] = useState(false);

    const handlePassValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordVal(e.target.value);
        if (validated) {
            setValidated(false);
        }
    }

    const getShowPass = async (passVal: string) => {
        const { response } = await authServices.getShowPass({
            password: passVal,
            user_id: userIds || "",
        });
        if (!response?.status) {
            snackbarUtil.error(response.msg)

        } else {
            setPassData(response);
        }
    }

    const handleHide = () => {
        clickHandler();
        setPasswordVal("");
        setPassword(false);
        setPassData(null);
        setValidated(false);
    }

    const handlePasswordShow = () => {
        if (passVal.trim() === "") {
            setValidated(true);
            return;
        }
        getShowPass(passVal);

    }

    const [copied, setCopied] = useState(false);
    const handleCopy = () => {
        setCopied(true);
        snackbarUtil.success("Copy Successful");
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    };

    return (
        <Modal show={show} onHide={handleHide} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>Show Password of {userName}</span>
                <Button variant="light btn-sm" onClick={handleHide}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className="fs-md">
                <Form noValidate validated={validated}>
                    <div className="form-floating">
                        {passData?.status ? (
                            <div className="">
                                Your Password is <strong>{passData?.password}</strong>
                                <CopyToClipboard text={passData?.password || ""} onCopy={handleCopy}>
                                    <i className='ri-file-copy-line' style={{ cursor: 'pointer' }}></i>
                                </CopyToClipboard>
                            </div>
                        ) : (
                            <>
                                <Form.Control
                                    isInvalid={validated && passVal.trim() === ""}
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                    value={passVal}
                                    onChange={handlePassValue}
                                    required
                                />
                                <Form.Label>Enter Logged In Password</Form.Label>
                                <Button
                                    variant="link"
                                    className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                                    type="button"
                                    id="password-addon"
                                    onClick={() => setPassword(!showPassword)}
                                >
                                    <i className={`${showPassword ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle`}></i>
                                </Button>
                                <Form.Control.Feedback type="invalid">
                                    Please enter your password.
                                </Form.Control.Feedback>
                            </>
                        )}
                    </div>
                </Form>
                {!passData?.status && (
                    <div className="text-end mt-3">
                        <Button
                            variant="subtle-primary"
                            onClick={handlePasswordShow}
                        >
                            Show Password
                        </Button>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default ShowPasswordModal;
