import BreadCrumb from "Common/BreadCrumb";
import CasinoResultTable from "Common/Tables/CasinoResultTable";
import { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import { Container, Button, Card, Form, Row, Col } from "react-bootstrap";
import { getLastTimeOfDay, getStartTimeOfDay } from "Utils/datefilter";
import Select from "react-select";
import { settingServices } from "Utils/setting/services";

const CasinoResult = () => {
  const [showFilter, setFilter] = useState(false);
  const [betType, setBetType] = useState<string>("open");
  const [userOption, setUserOption] = useState<any[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [betData, setBetData] = useState<lotusBetRes[]>([]);
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState<number>(1);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 1,
    page: 1,
  });
  const [startDate, setStartDate] = useState<string>(
    getStartTimeOfDay(new Date())
  );
  const [endDate, setEndDate] = useState<string>(getLastTimeOfDay(new Date()));

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBetType(e.target.value);
  };

  const getUserName = async (user_name: string) => {
    try {
      const { response } = await settingServices.getUserName({ user_name });
      const options = response?.data.map((user: any) => ({
        value: user?._id,
        label: user.user_name,
      }));
      setUserOption(options);
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  const getLotusBets = async (
    from_date: any,
    to_date: string,
    toLimit?: number,
    toPage?: number
  ) => {
    try {
      const payload = {
        bets_type: betType,
        from_date,
        to_date,
        limit: limit,
        page: page,
      } as {
        bets_type: string;
        from_date: string;
        limit: number;
        page: number;
        to_date: string;
        user_id?: string;
      };

      if (selectedUserId) {
        payload.user_id = selectedUserId;
      }
      if (toLimit) {
        payload.limit = toLimit;
      }
      if (toPage) {
        payload.page = toPage;
      }

      const { response } = await settingServices.getLotusBets(payload);
      setBetData(response?.data?.data);

      setListTotal({
        total: response?.data?.metadata
          ? response?.data?.metadata?.totalPages
          : 1,
        page: response?.data?.metadata
          ? response?.data?.metadata?.currentPage
          : 1,
      });
    } catch (error) {
      console.error("Error fetching bets:", error);
    }
  };

  useEffect(() => {
    getLotusBets(startDate, endDate);
  }, [betType, startDate, endDate, selectedUserId]);

  const handlePageFilter = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);

    getLotusBets(startDate, endDate, limit, page);
  };

  console.log(startDate, endDate, "endDateendDateendDate");

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Casino Result" pageTitle="Casino" />
        <Card>
          <Card.Body>
            <Row className="mb-2">
              <Col lg={3} className="mb-2 mb-lg-0">
                <Select
                  options={userOption}
                  placeholder="Select User"
                  onChange={(selected: any) => {
                    if (selected) {
                      setSelectedUserId(selected.value);
                    }
                  }}
                />
              </Col>
              <Col lg={3} className="mb-2 mb-lg-0">
                <Flatpickr
                  className="form-control"
                  value={startDate}
                  options={{
                    dateFormat: "Y-m-d",
                  }}
                  onChange={(date: any) => {
                    setStartDate(date[0].toISOString());
                  }}
                />
              </Col>
              <Col lg={3} className="mb-2 mb-lg-0">
                <Flatpickr
                  className="form-control"
                  value={endDate}
                  options={{
                    dateFormat: "Y-m-d",
                  }}
                  onChange={(date: any) => {
                    setEndDate(date[0].toISOString());
                  }}
                />
              </Col>
              <Col lg={3} className="text-end">
                <Button
                  variant="primary"
                  onClick={() => getLotusBets(startDate, endDate)}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        
        <Card>
          <Card.Body>
            <CasinoResultTable
              betData={betData}
              getLotusBets={getLotusBets}
              startDate={startDate}
              endDate={endDate}
              selectedUserId={selectedUserId}
              page={page}
              limit={limit}
              listTotal={listTotal}
              handlePageFilter={handlePageFilter}
            />
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default CasinoResult;
