import { Dispatch, SetStateAction, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { settingServices } from 'Utils/setting/services';

interface DomainSettingModalProps {
    show: boolean;
    clickHandler: any;
    updatePaylod: {
        host_name: string;
        id: string;
        password: string;
        site_title: string;
    };
    setUpdatePload: Dispatch<SetStateAction<{
        host_name: string;
        id: string;
        password: string;
        site_title: string;
    }>>;
    updateWebsiteSetting: (id: string) => void;
    nameDomain: any
}

const DomainSettingModal = ({ show, clickHandler, updatePaylod,nameDomain, setUpdatePload, updateWebsiteSetting }: DomainSettingModalProps) => {
    const [showPassword, setPassword] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [logoFile, setLogoFile] = useState<File | null>(null);
    const [bgImageFile, setBgImageFile] = useState<File | null>(null);
    const [isChecking, setIsChecking] = useState(false); 

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUpdatePload((prev) => ({ ...prev, [name]: value }));
        if (value.trim() !== '') {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
        }
    };

    const handleFileChange = (e: any, fileSetter: Dispatch<SetStateAction<File | null>>, errorKey: string) => {
        if (e.target.files && e.target.files.length > 0) {
            fileSetter(e.target.files[0]);
            setErrors((prevErrors) => ({ ...prevErrors, [errorKey]: '' }));
        }
    };

    const getCheckWebsiteName = async (host_name: string) => {
        setIsChecking(true);
        try {
            const { response } = await settingServices.getCheckWebsiteName({ host_name });
            if (response.status) {
                setErrors((prev) => ({ ...prev, host_name: '' }));
            } else {
                setErrors((prev) => ({ ...prev, host_name: 'Website name is not available' }));
            }
        } catch (error) {
            setErrors((prev) => ({ ...prev, host_name: 'Error checking website name' }));
        }
        setIsChecking(false);
    };

    const validate = () => {
        let valid = true;
        let newErrors: { [key: string]: string } = {};

        if (!updatePaylod.host_name.trim()) {
            newErrors.host_name = 'Website name is required';
            valid = false;
        }
        if (!updatePaylod.site_title.trim()) {
            newErrors.site_title = 'Site title is required';
            valid = false;
        }
        if (!updatePaylod.password.trim()) {
            newErrors.password = 'Password is required';
            valid = false;
        }
        if (!logoFile) {
            newErrors.logo = 'Logo file is required';
            valid = false;
        }
        if (!bgImageFile) {
            newErrors.bgImage = 'Background image file is required';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleSubmit = () => {
        if (validate()) {
            updateWebsiteSetting(updatePaylod?.id);
        }
    };

    return (
        <Modal size='lg' show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <div>Update Website Setting of {nameDomain}</div>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
                <Row className="align-items-center g-3">
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>Website Name</Form.Label>
                            <Form.Control
                                name="host_name"
                                value={updatePaylod?.host_name}
                                onChange={handleChange}
                                onFocus={() => getCheckWebsiteName(updatePaylod.host_name)} 
                                isInvalid={!!errors.host_name}
                                type="text"
                            />
                            <Form.Control.Feedback type="invalid">{errors.host_name}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>Site Title</Form.Label>
                            <Form.Control
                                name="site_title"
                                value={updatePaylod?.site_title}
                                onChange={handleChange}
                                isInvalid={!!errors.site_title}
                                type="text"
                            />
                            <Form.Control.Feedback type="invalid">{errors.site_title}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>Logo</Form.Label>
                            <Form.Control
                                accept="image/*"
                                type="file"
                                onChange={(e) => handleFileChange(e, setLogoFile, "logo")}
                                isInvalid={!!errors.logo}
                            />
                            <Form.Control.Feedback type="invalid">{errors.logo}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>Bg Image</Form.Label>
                            <Form.Control
                                accept="image/*"
                                type="file"
                                onChange={(e) => handleFileChange(e, setBgImageFile, "bgImage")}
                                isInvalid={!!errors.bgImage}
                            />
                            <Form.Control.Feedback type="invalid">{errors.bgImage}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <div className="form-icon right">
                                <Form.Control
                                    name="password"
                                    value={updatePaylod?.password}
                                    onChange={handleChange}
                                    isInvalid={!!errors.password}
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                />
                                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                <Button
                                    variant="link"
                                    className="btn-sm position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                                    type="button"
                                    id="password-addon"
                                    onClick={() => setPassword(!showPassword)}
                                >
                                    <i className={showPassword ? "ri-eye-fill align-middle" : "ri-eye-off-fill align-middle"}></i>
                                </Button>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='border-top'>
                <Button onClick={handleSubmit}>Update</Button>
                <Button variant='light' onClick={clickHandler}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DomainSettingModal;
