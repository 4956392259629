import BreadCrumb from "Common/BreadCrumb";
import { ListPagination } from "Common/ListPagination";
import TableContainer from "Common/TableContainer";
import TableEntry from "Common/TableEntry";
import { useEffect, useMemo, useState } from "react";
import { Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { settingServices } from "Utils/setting/services";

const UserMobileData = () => {
  const [userData, setUserData] = useState<userNameResponse[]>([])
  const [limit, setLimit] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [totalData, setTotalData] = useState<number>(0);

  const getUserMobileName = async () => {
    const { response } = await settingServices.getUserMobileName({
      limit,
      page: page
    })

    setUserData(response?.data?.data);
    setTotalData(response?.data?.metadata?.total || 0)
  }

  useEffect(() => {
    getUserMobileName();
  }, [limit, page])

  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "no",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return ((page - 1) * limit) + cell?.row?.index + 1
        }
      },
      {
        header: "Domain",
        accessorKey: "domain_name",
        enableColumnFilter: false,
      },
      {
        header: "User Name",
        accessorKey: "userName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <div>{cell.row.original.user_name} [{cell.row.original.name}]</div>
        }
      },
      {
        header: "Mobile",
        accessorKey: "mobile",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const mobileNumber = cell.row.original.mobile;
          const whatsappLink = `https://wa.me/${mobileNumber}`;
          return (<div>
            <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
              {mobileNumber}
            </a>
          </div>)
        }
      },
    ],
    [userData, page, limit]
  );

  return (
    <div className="page-content">
      <Container fluid>
        <div className="position-relative mb-2 d-flex justify-content-between align-items-center">
          <BreadCrumb title="User Mobile Data" pageTitle="Dashboard" back />
        </div>

        <Card>
          <Card.Body>
            <TableContainer
              columns={columns || []}
              data={userData || []}
              isBordered={false}
              customPageSize={limit}
              isPagination={false}
              tableClass="table-centered align-middle table-nowrap table-striped mb-3"
              theadClass="text-muted table-light"
              SearchPlaceholder="Search Products..."
              setLimit={setLimit}
            />
            <nav aria-label="Page navigation" className="px-3 d-flex flex-column flex-sm-row align-items-center">
              <TableEntry
                limit={limit}
                handlelimitFilter={(value: number) => setLimit(value)}
                minLimit={100}
              />
              <ListPagination
                listTotal={{
                  total: totalData/limit,
                  page: page,
                }}
                handlePageFilter={(value: number) => setPage(value)}
              />
            </nav>

          </Card.Body>
        </Card>
      </Container>

    </div>
  );
};

export default UserMobileData;