import BreadCrumb from "Common/BreadCrumb";
import { useState } from "react";
import { Container, Button, Card, Form, Modal } from "react-bootstrap";

const Setting = () => {
    const[showTv, setTv] = useState(false)
    const toggleTv = () => {
        setTv(!showTv)
    }
    return (
        <div className="page-content">
            <Container fluid>
                <div className="position-relative mb-3 d-flex justify-content-between align-items-center">
                    <BreadCrumb title='Setting' pageTitle="Dashboard" back />
                    <Button variant="subtle-dark" onClick={toggleTv}><i className="fs-lg align-middle ri-tv-line me-1"></i> Reset Tv</Button>
                </div>

                <Card>
                    <Card.Body>
                        <h5>Reset TV and ScoreBoard URL</h5>
                    </Card.Body>
                </Card>

                <Modal show={showTv} onHide={toggleTv} className="zoomIn" scrollable>
                    <Modal.Header className="modal-title fw-bold">
                        <div>Reset TV and ScoreBoard URL</div>
                        <Button variant="light btn-sm" onClick={toggleTv}>
                            <i className="ri-close-line fs-xl align-middle"></i>
                        </Button>
                    </Modal.Header>
                    <Modal.Body className="pt-2">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Enter Password" />
                    </Modal.Body>
                    <Modal.Footer className="border-top">
                        <Button>Update</Button>
                        <Button variant="subtle-dark" onClick={toggleTv}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </div>
    );
};

export default Setting;