import { FC, useEffect, useMemo } from 'react';
import TableContainer from 'Common/TableContainer';
import { Card, Button } from 'react-bootstrap';
import { GroupedData } from 'pages/Dashboard';
import { authServices } from 'Utils/auth/services';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';


interface Props {
    sportData: GroupedData | undefined;
    sportId: number;
    getEnabaledFancy: any,
    setShow: React.Dispatch<React.SetStateAction<boolean>>
    setUpdatePaylod: React.Dispatch<React.SetStateAction<{
        matchId: string;
        isActive: number;
    }>>
    active:number;
    setShowFancy: React.Dispatch<React.SetStateAction<boolean>>
    setUpdatePaylodFancy: React.Dispatch<React.SetStateAction<{
        matchId: string;
        enable_fancy: number;
        keys: string;
    }>>
}

const sportName: { [key: string]: string } = {
    "4": "Cricket",
    "9898": "Share Market",
    "1": "Soccer",
    "2": "Tennis",
    "-100": "Casino",
    "7": "Horse Racing",
    "4339": "Greyhound Racing",
    "QT": "QTech"
};



const DashboardTableAll: FC<Props> = ({ sportData,setShowFancy, sportId, getEnabaledFancy, setShow, setUpdatePaylod, active, setUpdatePaylodFancy }) => {


    const handleActiveDeActive = (matchedId: string, enable_fancy: number, keys:string) => {
        setShowFancy(true);
        setUpdatePaylodFancy({
            matchId:matchedId,
            enable_fancy,
            keys:keys
        })

    };

    const handleUpdateMatchStatus = (matchedId: string, isActive: number): void => {
        setShow(true)
        setUpdatePaylod({
            matchId: matchedId,
            isActive
        })
    };


    const nav = useNavigate();

    const handleRoute=(matchid:string, sport_id:string, enableFancy:number, matchName:string, isActive:number)=>{
        nav( `/match-detail/${matchid}/${sport_id}/${enableFancy}`, { state:matchName});
        localStorage.setItem("isAct", String(isActive) );
    }

   


    const columns = useMemo(() => [
        {
            header: "Match Details",
            accessorKey: "name",
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell: any) => (
                <div className='cursor-pointer' onClick={()=>handleRoute(cell?.row?.original?.matchid, cell?.row?.original?.sport_id, cell.row.original?.enableFancy,cell.row.original.matchName, cell?.row?.original?.isActive)}>
                    <div className='text-dark fs-lg  d-inline' >{cell.row.original.matchName} ({cell.row.original.bet_count})</div>
                    <div className='d-flex align-items-center'>
                        <span className='inplayBlink'>{cell.row.original.inplay}</span>
                        {
                            cell.row.original.inplay === "INPLAY" && <span className='mx-2 text-muted'>|</span>
                        }

                        {cell.row.original.date && (
                            <>
                                <span className='text-muted'>{cell.row.original.date}</span>
                                <span className='mx-2 text-muted'>|</span>
                            </>
                        )}
                        <span className='text-primary'>{cell.row.original.seriesName}</span>
                    </div>
                </div>
            ),
        },
        {
            header: "Action",
            accessorKey: "Action",
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell: any) => (
                <div className='btn-group'>
                    { cell.row.original.key !== "Share Market" && (<>
                        <Button variant={`${cell?.row?.original?.enableFancy?"subtle-info":"subtle-danger"} fs-xl px-2 py-1`} onClick={() => handleActiveDeActive(cell?.row?.original?.matchid, cell?.row?.original?.enableFancy, cell?.row?.original?.key)} style={{minWidth: '35px'}}>F</Button>
                        {
                           cell?.row?.original?.isTv && <Button variant="subtle-dark px-2 py-1">
                           <i className='ri-tv-line fs-xl'></i>
                       </Button>
                        }
                        
                    </>
                    )}
                    <Button variant={`${cell?.row?.original?.isActive?"subtle-success":"subtle-danger"} fs-xl px-2 py-1`} onClick={() => handleUpdateMatchStatus(cell?.row?.original?.matchid, cell?.row?.original?.isActive)} style={{minWidth: '35px'}}>A</Button>
                </div>
            ),
        },
        {
            header: "Odds",
            accessorKey: "Odds",
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell: any) => (
                <div className='oddsBtn d-inline-flex align-items-center position-relative '>
                    <div className='btn-group'>
                        <Button style={{ width: '55px' }} variant='back'>{cell?.row?.original?.odds?.ex?.availableToBack?.[0]?.price || '-'}</Button>
                        <Button style={{ width: '55px' }} variant='lay'>{cell?.row?.original?.odds?.ex?.availableToLay?.[0]?.price || '-'}</Button>
                    </div>
                    <div className='btn-group mx-1'>
                        <Button style={{ width: '55px' }} variant='back'>0</Button>
                        <Button style={{ width: '55px' }} variant='lay'>0</Button>
                    </div>
                    <div className='btn-group'>
                        <Button style={{ width: '55px' }} variant='back'>{cell?.row?.original?.odds?.ex?.availableToBack?.[2]?.price || '-'}</Button>
                        <Button style={{ width: '55px' }} variant='lay'>{cell?.row?.original?.odds?.ex?.availableToLay?.[2]?.price || '-'}</Button>
                    </div>
                    {cell.row.original.status !== "OPEN" && (
                        <div className='position-absolute w-100 h-100 border rounded-2 bg-white bg-opacity-75 d-flex align-items-center justify-content-center fs-md text-danger border-danger text-uppercase fw-bold'>
                            {cell.row.original.status}
                        </div>
                    )}
                </div>
            ),
        },
    ], []);

    const generateTableData = (key: string) => {
        if (!sportData || !sportData[key] || !Array.isArray(sportData[key])) {
            return [];
        }
    
        return sportData[key]
            .filter(items => (active === 200 ? items?.inplay : true))  
            .map((items) => ({
                matchName: items?.match_name,
                inplay: items?.inplay && 'INPLAY',
                sport: items?.sport_name,
                odds: items?.runners[0],
                status: items?.status,
                seriesName: items?.series_name,
                matchid: items?.match_id,
                isActive: items?.is_active,
                sport_id:items?.sport_id,
                key: key,
                enableFancy: items.enable_fancy,
                bet_count: items.bet_count,
                date: items?.match_date
                    ? moment(items?.match_date).format('DD-MM-YY hh:mm A')
                    : null,
                    isTv:items?.match_tv_url
            }));
    };

    return (
        <>
            {sportData ? Object.keys(sportData).map((key) =>{
                 const filteredData = generateTableData(key);
                 if (filteredData.length === 0 || key?.toLowerCase()==="casino") return null;
 
                 const displayCount = active === 200
                     ? filteredData.filter(item => item.inplay === 'INPLAY').length
                     : filteredData.length;

                     console.log(key, "sportDatasportData")
                 return(
                <Card className='mt-3' key={key}>
                    <Card.Header>
                        <h5>{key} <span className='text-muted'>({displayCount || 0})</span></h5>
                    </Card.Header>
                    <Card.Body className='pt-1 px-4'>
                        <TableContainer
                            isPagination={false}
                            columns={columns}
                            data={generateTableData(key)}
                            isBordered={false}
                            divClassName="table-responsive table-card mt-0"
                            tableClass="table table-striped align-middle table-nowrap mb-0"
                            theadClass="d-none"
                            tdClass="w-100"
                        />
                    </Card.Body>
                </Card>
            )}):<div>No Data Found</div>}
        </>
    );
};

export default DashboardTableAll;
