import { balancePayload } from "pages/Reports/DownLineReport";
import { FC, useState } from "react";
import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { authServices } from "Utils/auth/services";

interface Props{
  balanceData: balancePayload | undefined
}

const BalanceTable:FC<Props> = ({balanceData}) => {
  return (
    <>
      <div className="table-responsive">
        <Table className="table-centered align-middle table-nowrap table-striped">
          <tbody>
            <tr>
              <td>
                <span className="cursor-pointer text-info">Net Exposure</span>
              </td>
              <td
                className={`text-${Number(balanceData?.liability) < 0 ? "danger" : "success" }`}
              >
                {balanceData?.liability?.toFixed(2)}
              </td>
            </tr>
            <tr>
              <td>
                <span className="cursor-pointer text-info">P | L</span>
              </td>
              <td
                className={`text-${Number(balanceData?.profit_loss) < 0 ? "danger" : "success" }`}
              >
                {balanceData?.profit_loss?.toFixed(2)}
              </td>
            </tr>
            <tr>
              <td>
                <span className="cursor-pointer text-info">
                  Available Credit
                </span>
              </td>
              <td
                className={`text-${Number(balanceData?.balance) < 0 ? "danger" : "success"}`}
              >
                {balanceData?.balance?.toFixed(2)}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default BalanceTable;
