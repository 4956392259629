import Flatpickr from "react-flatpickr";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import ProfitLossTable from "Common/Tables/ProfitLossTable";
import { getLastTimeOfDay, getStartTimeOfDay } from "Utils/datefilter";
import { useEffect, useState, useMemo, useRef } from "react";
import { authServices } from "Utils/auth/services";
import {
  clearSearchPayload,
  generateOptions,
  updateSearchPayload,
} from "Utils/searchFilter";
import { useNavigationType } from "react-router-dom";
import Loader from "Common/Loader";

const options = [
  { value: "Sport", label: "Sport Name" },
  { value: "Series", label: "Series Name" },
  { value: "Match", label: "Match Name" },
  { value: "Market", label: "Market Name" },
];

interface StatementProps {
  filter?: boolean;
  activeTab: string;
}

const ProfitLoss = ({ filter, activeTab }: StatementProps) => {
  const currentDate = new Date();
  const sevenDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 7));
  
  const [startDate, setStartDate] = useState<string>(
    getStartTimeOfDay(sevenDaysAgo)
  );
  const [endDate, setEndDate] = useState<string>(getLastTimeOfDay(new Date()));
  const [profitLossData, setProfitLossData] = useState<ProfitLossData[]>([]);
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });
  const [profitLossSum, setProfitLossSum] = useState<ProfitLossSum>({
    p_l: 0,
    commission: 0,
    net_pl: 0,
  });
  const [searchList, setSearchList] = useState<SportSearchList>();
  const [searchPayload, setSearchPayload] = useState<SportsSearchPayloadData>(
    {}
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigationType = useNavigationType();
  const isInitialRender = useRef(true);
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    localStorage.setItem("profitLossStart", startDate);
    localStorage.setItem("profitLossEnd", endDate);
  }, [startDate, endDate]);

  const eventOptions = useMemo(
    () =>
      generateOptions(
        searchList?.events_m_f || [],
        "event_id",
        "event_name",
        "type"
      ),
    [searchList?.events_m_f]
  );
  const matchOptions = useMemo(
    () => generateOptions(searchList?.matches || [], "match_id", "match_name"),
    [searchList?.matches]
  );
  const seriesOptions = useMemo(
    () => generateOptions(searchList?.series || [], "series_id", "series_name"),
    [searchList?.series]
  );
  const sportsOptions = useMemo(
    () => generateOptions(searchList?.sports || [], "sport_id", "sport_name"),
    [searchList?.sports]
  );

  const getProfitLossList = async (filters: any = {}) => {
    setIsLoading(true);
    const { response } = await authServices.getProfitLossList({
      limit: limit,
      page: page,
      ...filters,
    });
    setIsLoading(false);
    if (response?.data && response?.data?.data) {
      setProfitLossData(response?.data?.data);
      setListTotal({
        total: response?.data?.metadata
          ? response?.data?.metadata?.total / (filters?.limit || limit)
          : 1,
        page: response?.data?.metadata
          ? response?.data?.metadata?.page
          : 1,
      });

      let dataList = response?.data?.data;
      var totalPL = 0;
      var totalCommission = 0;
      var net_pl = 0;
      for (let i = 0; i < dataList.length; i++) {
        totalPL = totalPL + dataList[i].p_l;
        totalCommission = totalCommission + dataList[i].commission;
        net_pl = net_pl + dataList[i].net_pl;
      }
      setProfitLossSum({
        p_l: totalPL,
        commission: totalCommission,
        net_pl: net_pl,
      });
    }
  };

  const getSportSearchList = async (filters: any = {}) => {
    const { response } = await authServices.getSportsSearchList({
      type: "eventsProfitLoss",
      ...filters,
    });
    if (response?.data && response?.data.length > 0) {
      setSearchList(response?.data[0]);
    }
  };

  useEffect(() => {
    let start =
      navigationType === "POP"
        ? localStorage.getItem("profitLossStart")
        : startDate;
    let end =
      navigationType === "POP" ? localStorage.getItem("profitLossEnd") : endDate;
    start && setStartDate(start);
    end && setEndDate(end);

    if (activeTab === "Market") {
      getProfitLossList({
        from_date: start,
        to_date: end,
      });
      getSportSearchList();
    }
  }, [activeTab]);

  const handlefilterSubmit = () => {
    getProfitLossList({
      from_date: startDate,
      to_date: endDate,
      search: searchPayload,
    });
  };

  const handlePageFilter = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);
    getProfitLossList({
      from_date: startDate,
      to_date: endDate,
      search: searchPayload,
      page: page,
      limit: limit,
    });
  };

  const handleSearchFilter = (type: SearchPayloadType, id: string) => {
    const payload = updateSearchPayload(type, id, searchPayload);
    getSportSearchList({ search: payload });
    setSearchPayload(payload);
    getProfitLossList({
      from_date: startDate,
      to_date: endDate,
      search: payload,
    });
  };

  const handleClearSelection = (type: SearchPayloadType) => {
    const payload = clearSearchPayload(type, searchPayload);
    getSportSearchList({ search: payload });
    setSearchPayload(payload);
    getProfitLossList({
      from_date: startDate,
      to_date: endDate,
      search: payload,
    });
  };

  const handleAllClearSelection = (type: SearchPayloadType) => {
    const payload = clearSearchPayload(type, searchPayload);
    getSportSearchList({ search: payload });
    setSearchPayload(payload);
    setStartDate(getStartTimeOfDay(sevenDaysAgo));
    setEndDate(getLastTimeOfDay(new Date()));

    getProfitLossList({
      from_date: getStartTimeOfDay(sevenDaysAgo),
      to_date: getLastTimeOfDay(new Date()),
      search: {},
    });
  };

  return (
    <>
      {filter ? (
        <Card>
          <Card.Body>
            <Form>
              <Row className="gy-3 align-items-end">
                <Col lg={3}>
                  <Form.Label>Start Date</Form.Label>
                  <Flatpickr
                    className="form-control"
                    pl
                    options={{
                      enableTime: false,
                      dateFormat: "Y-m-d",
                      defaultDate: [startDate],
                      maxDate: endDate,
                    }}
                    value={startDate}
                    onChange={([date]: [Date]) => {
                      setStartDate(getStartTimeOfDay(new Date(date)));
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>End Date</Form.Label>
                  <Flatpickr
                    className="form-control"
                    pl
                    options={{
                      enableTime: false,
                      dateFormat: "Y-m-d",
                      defaultDate: [endDate],
                      maxDate: getLastTimeOfDay(new Date()),
                    }}
                    value={endDate}
                    onChange={([date]: [Date]) => {
                      setEndDate(getLastTimeOfDay(new Date(date)));
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search Sport Name</Form.Label>
                  <Select
                    isClearable
                    options={sportsOptions}
                    value={
                      sportsOptions.find(
                        (option: any) =>
                          option.value === searchPayload?.sport_id
                      ) || null
                    }
                    className="customSelect"
                    onChange={(e: any) => {
                      if (e) {
                        handleSearchFilter("sport_id", e.value);
                      } else {
                        handleClearSelection("sport_id");
                      }
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search Series Name</Form.Label>
                  <Select
                    isClearable
                    options={seriesOptions}
                    value={
                      seriesOptions.find(
                        (option: any) =>
                          option.value === searchPayload?.series_id
                      ) || null
                    }
                    className="customSelect"
                    onChange={(e: any) => {
                      if (e) {
                        handleSearchFilter("series_id", e.value);
                      } else {
                        handleClearSelection("series_id");
                      }
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search Match Name</Form.Label>
                  <Select
                    isClearable
                    options={matchOptions}
                    value={
                      matchOptions.find(
                        (option: any) =>
                          option.value === searchPayload?.match_id
                      ) || null
                    }
                    className="customSelect"
                    onChange={(e: any) => {
                      if (e) {
                        handleSearchFilter("match_id", e.value);
                      } else {
                        handleClearSelection("match_id");
                      }
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search Market Name</Form.Label>
                  <Select
                    isClearable
                    options={eventOptions}
                    value={
                      eventOptions.find(
                        (option: any) =>
                          option.value === searchPayload?.fancy_id ||
                          option.value === searchPayload?.market_id
                      ) || null
                    }
                    className="customSelect"
                    onChange={(e: any) => {
                      if (e) {
                        handleSearchFilter(
                          e.type == "1" ? "market_id" : "fancy_id",
                          e.value
                        );
                      } else {
                        handleClearSelection("market_id");
                      }
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Button
                    className="me-2 btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handlefilterSubmit()}
                  >
                    <i className="fs-lg align-middle ri-filter-line"></i> Submit
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handleAllClearSelection("sport_id")}
                  >
                    <i className="fs-lg align-middle ri-refresh-line"></i> Clear
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      ) : null}
      <Card>
        <Card.Body>
          <ProfitLossTable
            profitLossData={profitLossData}
            page={page}
            limit={limit}
            listTotal={listTotal}
            profitLossSum={profitLossSum}
            handlePageFilter={handlePageFilter}
            startDate={startDate}
            endDate={endDate}
          />
        </Card.Body>
      </Card>
      {isLoading && <Loader />}
    </>
  );
};

export default ProfitLoss;
