import TableEntry from 'Common/TableEntry';
import { useEffect, useMemo, useState } from 'react';
import { Button, Modal, Nav, Tab, Table, Form } from 'react-bootstrap';
import { authServices } from 'Utils/auth/services';

interface ExposureModalProps {
    show: any;
    clickHandler: any;
    userIds: string;
    userName: string;
}

const ExposureModal = ({ show, clickHandler, userIds, userName }: ExposureModalProps) => {
    const [sportsData, setSportsData] = useState<SportData[]>([]);
    const [casinoData, setCasinoData] = useState<any[]>([]);
    const [fullExp, setFullExc] = useState<boolean>(true);

    const fetchExposureSport = async () => {
        try {
            const { response } = await authServices.getExposureSport({ user_id: userIds });
            const filteredData = response.data.filter((item: SportData) => !('liabilitySum' in item));
            setSportsData(filteredData);
        } catch (error) {
            console.error('Error fetching exposure sport data:', error);
        }
    };
    const fetchExposureSportV1 = async () => {
        try {
            const { response } = await authServices.getExposureSportV1({ user_id: userIds });
            const filteredData = response.data.filter((item: SportData) => !('liabilitySum' in item));
            setSportsData(filteredData);
        } catch (error) {
            console.error('Error fetching exposure sport data:', error);
        }
    };
    const fetchExposureCasino = async () => {
        try {
            const { response } = await authServices.getExposureCasino({ user_id: userIds });
            setCasinoData(response.data)
        } catch (error) {
            console.error('Error fetching exposure casino data:', error);
        }
    };

    useEffect(() => {
        if (show) {
            if (fullExp) {
                fetchExposureSport()
            } else {
                fetchExposureSportV1();
            }
        }
    }, [show, fullExp]);



    const renderTableRows = (data: SportData[]) =>
        data.map((item, index) => (
            <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.sport_name}</td>
                <td>{item.match_name}</td>
                <td>{item.event_name}</td>
                <td style={{ color: item.liability < 0 ? 'red' : 'green' }}>
                    {item.liability?.toFixed(2)}
                </td>
            </tr>
        ));



    const totals = useMemo(() => {
        return sportsData?.reduce(
            (acc, user) => {
                acc.liability += user.liability;
                return acc;
            },
            { liability: 0 }
        );
    }, [sportsData]);


    return (
        <Modal size="lg" show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between">
                <div className='d-flex'>
                    <span>Exposure of {userName}</span>
                    <Form.Check checked={fullExp} onChange={(e) => setFullExc(e.target.checked)} type="checkbox" role="switch" className='ms-3' id="tableOnOff" />
                </div>

                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='pt-1'>
                <Tab.Container defaultActiveKey="sports">
                    <Nav as="ul" variant="tabs" className="nav-tabs-custom nav-primary nav-justified mb-3">
                        <Nav.Item as="li">
                            <Nav.Link eventKey="sports" onClick={fetchExposureSport}>
                                Sports
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link eventKey="casino" onClick={fetchExposureCasino}>
                                Casino
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className="text-muted">
                        <Tab.Pane eventKey="sports">
                            <Table className="table-striped table-nowrap align-middle mb-0">
                                <thead>
                                    <tr>
                                        <th>S. No.</th>
                                        <th>Sport Name</th>
                                        <th>Event Name</th>
                                        <th>Market</th>
                                        <th>Exposure</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderTableRows(sportsData)}
                                    <tr>
                                        <td colSpan={4} className='fw-bolder text-center'>Total</td>
                                        <td className={`fw-bolder ${totals?.liability > 0 ? "text-success" : "text-danger"}`}>{totals?.liability?.toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Tab.Pane>
                        <Tab.Pane eventKey="casino">
                            {
                                casinoData?.length === 0 ? <div style={{
                                    fontSize: "14",
                                    textAlign: "center"
                                }}>No Data Found</div> :

                                    <Table className="table-striped table-nowrap align-middle mb-0">
                                        <thead>
                                            <tr>
                                                <th>S. No.</th>
                                                <th>Sport Name</th>
                                                <th>Event Name</th>
                                                <th>Market</th>
                                                <th>Exposure</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* Example rows - Replace with actual data */}
                                            <tr>
                                                <td>01</td>
                                                <td>Horse Racing</td>
                                                <td>Seymour (AUS) 27th Aug</td>
                                                <td>To Be Placed</td>
                                                <td><span className="text-danger">-200</span></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                            }
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </Modal.Body>
        </Modal>
    );
};

export default ExposureModal;