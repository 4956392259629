import Loader from "Common/Loader";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

interface EditStackModalProps {
  show: boolean;
  clickHandler: () => void;
}

const EditStackModal = ({ show, clickHandler }: EditStackModalProps) => {
  const [stack, setStack] = useState<number[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userId = localStorage.getItem("userId");

  const getStackStatus = async () => {
    if(!userId) return null;
    setIsLoading(true);
    const { response } = await authServices.getMatchStack({
      user_id: userId || "",
    });
    setIsLoading(false);
    if (response.status) {
      setStack(response.match_stack);
    }
  };

  useEffect(() => {
    show && getStackStatus();
  }, [show]);

  const updateStackStatus = async () => {
    setIsLoading(true);
    const { response } = await authServices.getUpdateStack({match_stack: stack});
    setIsLoading(false);
    if (response.status) {
      clickHandler();
      getStackStatus();
      snackbarUtil.success(response?.msg);
    }  else {
      const errorMessage =
        response?.msg || "An error occurred. Please try again.";
      snackbarUtil.error(errorMessage);
    }
  };

  const handleChipValueChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedValue = Number(e.target.value);
    const updatedStack = [...(stack || [])];
    updatedStack[index] = updatedValue;
    setStack(updatedStack);
  };

  const handleSubmit = () => {
    updateStackStatus();
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={clickHandler}
      className="zoomIn"
      scrollable
    >
      <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
        <div>Change Stack Value</div>
        <Button variant="light btn-sm" onClick={clickHandler}>
          <i className="ri-close-line fs-xl align-middle"></i>
        </Button>
      </Modal.Header>

      <Modal.Body>
        <Row className="g-3">
        {stack?.map((item: number, index: number) => {
            return (
              <Col md={6}>
                <Form.Label>Chip Value {index + 1}</Form.Label>
                <Form.Control
                  type="number"
                  value={item}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChipValueChange(e, index)
                  }
                />
              </Col>
            );
          })}
        </Row>
      </Modal.Body>

      <Modal.Footer className="border-top">
        <Button onClick={()=>handleSubmit()}>Submit</Button>
        <Button variant="subtle-dark" onClick={clickHandler}>Cancel</Button>
      </Modal.Footer>
      {isLoading && <Loader />}
    </Modal>
  );
};

export default EditStackModal;
