import BreadCrumb from "Common/BreadCrumb";
import DepositTable from "Common/Tables/DepositTable";
import TotalDepositTable from "Common/Tables/TotalDepositTable";
import { Container, Card, Button, Row, Col, Form, } from "react-bootstrap";
import Flatpickr from "react-flatpickr";

const Deposit = () => {
    return (
        <div className="page-content">
            <Container fluid>
                <div className="position-relative mb-3 d-flex justify-content-between align-items-center">
                    <BreadCrumb title='Deposit' pageTitle="Dashboard" back />
                    <Button variant="subtle-dark"><i className="fs-lg align-middle ri-refresh-line"></i> Refresh</Button>
                </div>

                <Card>
                    <Card.Header className="border-bottom pb-3">
                        <Row className="align-items-end g-3">
                            <Col lg={3}>
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" placeholder="Enter Username" />
                            </Col>
                            <Col lg={3}>
                                <Form.Label>Amount</Form.Label>
                                <Form.Control type="number" placeholder="Enter Amount" />
                            </Col>
                            <Col lg={3}>
                                <Button className="me-2">Search</Button>
                                <Button variant="subtle-primary">Clear</Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <DepositTable />
                    </Card.Body>
                </Card>

                <h4 className="mt-4">Deposit All <span className="text-muted">(Total Deposit : 0)</span></h4>
                <Card className="mt-3">
                    <Card.Header className="border-bottom pb-3">
                        <Row className="align-items-end g-3">
                            <Col lg={3}>
                                <Form.Label>Start Date</Form.Label>
                                <Flatpickr
                                    className="form-control"
                                    options={{
                                        dateFormat: "Y-m-d",
                                        defaultDate: ["2022-01-20"]
                                    }}
                                />
                            </Col>
                            <Col lg={3}>
                                <Form.Label>End Date</Form.Label>
                                <Flatpickr
                                    className="form-control"
                                    options={{
                                        dateFormat: "Y-m-d",
                                        defaultDate: ["2022-01-20"]
                                    }}
                                />
                            </Col>
                            <Col lg={3}>
                                <Form.Label>Min Amount</Form.Label>
                                <Form.Control type="number" placeholder="Enter Min Amount" />
                            </Col>
                            <Col lg={3}>
                                <Form.Label>Max Amount</Form.Label>
                                <Form.Control type="number" placeholder="Enter Max Amount" />
                            </Col>
                            <Col lg={3}>
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" placeholder="Enter Username" />
                            </Col>
                            <Col lg={3}>
                                <Form.Label>Parent Username</Form.Label>
                                <Form.Control type="text" placeholder="Enter Parent Username" />
                            </Col>
                            <Col lg={3}>
                                <Form.Label>Parent Username</Form.Label>
                                <div className="d-flex">
                                    <Form.Select className="me-1 w-75">
                                        <option>All</option>
                                        <option>Accepted</option>
                                        <option>Rejected</option>
                                    </Form.Select>
                                    <Form.Control type="number" placeholder="Enter UTR No." />
                                </div>                                    
                            </Col>
                            <Col lg={3}>
                                <Button className="me-2">Search</Button>
                                <Button variant="subtle-primary">Clear</Button>
                                <Button variant="success" className="ms-2"><i className="fs-md bi bi-file-earmark-pdf-fill"></i></Button>
                                <Button variant="danger" className="ms-2"><i className="fs-md bi bi-file-earmark-excel-fill"></i></Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <TotalDepositTable />
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
};

export default Deposit;