import ToggleSwitch from 'Common/ToggleSwitch';
import { useState } from 'react';
import { Button, Modal, Form, Table } from 'react-bootstrap';

interface FancyBookProps {
    show: any;
    setFancyBook: any;
    fancyRunData: any;
    fancyName: string
}

const FancyBook = ({ show, setFancyBook, fancyName, fancyRunData }: FancyBookProps) => {
    const [showToggle, setShowToggle] = useState(true)
    return (
        <Modal size='lg' show={show} onHide={() => setFancyBook(false)} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <div className='d-flex align-items-center'>Fancy Position <span className='text-muted fw-normal mx-2'>{fancyName}</span> 
                <ToggleSwitch On='On' Off='Off' id='fancy_book' onChange={() => setShowToggle(!showToggle)} checked={showToggle ? true : false} />

                </div>
                <Button variant="light btn-sm" onClick={() => setFancyBook(false)}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='fs-md'>
                <div className="table-responsive">
                    <Table className="table-striped table-nowrap table-bordered text-center align-middle mb-0">
                        <thead>
                            <tr>
                                <th scope="col">Runs</th>
                                <th scope="col">P&L</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                fancyRunData?.map((items: any) => {
                                    return (
                                        <tr>
                                            <td>{items?.key}</td>
                                            <td className={items?.value>0?"text-success":'text-danger'}>{showToggle? items?.value:items?.valueFull}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default FancyBook;