import BreadCrumb from "Common/BreadCrumb";
import BannerSettingTable from "Common/Tables/BannerSettingTable";
import { useEffect, useState } from "react";
import { Container, Button, Card, Modal, Form } from "react-bootstrap";
import Select from "react-select";
import { settingServices } from "Utils/setting/services";
import snackbarUtil from "Utils/snackBarUtil";

const optionData = [
    { value: "Slider", label: "Slider" },
    { value: "Logo", label: "Logo" },
    { value: "Back Ground", label: "Back Ground" },
    { value: "Privacy Policy", label: "Privacy Policy" },
    { value: "Kyc", label: "Kyc" },
    { value: "Terms and Conditions", label: "Terms and Conditions" },
    { value: "Rules and Regulations", label: "Rules and Regulations" },
    { value: "Responsible Gambling", label: "Responsible Gambling" },
];

const BannerSetting = () => {
    const [showBanner, setBanner] = useState(false);
    const [bannerData, setBannerData] = useState<bannerPayload[]>([]);


    const [formData, setFormData] = useState<any>({
        type: null,
        title: "",
        website: "",
        device: "",
        image: null,
        category: false
    });

    const [errors, setErrors] = useState<any>({
        title: false,
        website: false,
        device: false,
        image: false
    });

    const toggleBanner = () => {
        setBanner(!showBanner);
    };

    const getContent = async (content_type: string) => {
        const { response } = await settingServices.getContent(content_type);
        setBannerData(response?.data);
    };

    const getDeleted = async (id: string) => {
        const { response } = await settingServices.getDeleted(id);
        if (response?.status) {
            snackbarUtil.success(response?.msg);
            getContent("Slider");
        }else{
            snackbarUtil.error(response?.msg);
        }
    };
    const getUploadContent = async (payload: any) => {
        const { response } = await settingServices.getUploadContent(payload);
        if (response?.status) {
            snackbarUtil.success(response?.msg);
            getContent("Slider");
        }else{
            snackbarUtil.error(response?.msg);
        }
    };
    const getUploadContentAgent = async (title: string, slug:string, category:string, submissionData1:any) => {
        const { response } = await settingServices.getUploadContentAgent(title,slug, category, submissionData1);
        if (response?.status) {
            snackbarUtil.success(response?.msg);
            getContent("Slider");
        }else{
            snackbarUtil.error(response?.msg);
        }
    };

    useEffect(() => {
        getContent("Slider");
    }, []);

    const userDetails = localStorage.getItem("adminDetails");
    const userId = userDetails && JSON.parse(userDetails)?.user_type_id;



    const handleChange = (field: string, value: any) => {

        setFormData({
            ...formData,
            [field]: value
        });
    };

   


    const validateForm = () => {
        const newErrors = {
            title: !formData.title.trim(),
            website: !formData.website.trim(),
            image: !formData.image
        };


        setErrors(newErrors);

        return !Object.values(newErrors).includes(true);
    };


    const handleSubmit = async () => {
        if (validateForm()) {
            const submissionData = new FormData();
            submissionData.append('content_type', formData.type?.value || "");
            submissionData.append('title', formData.title);
            submissionData.append('website', formData.website);
            if (formData.image) {
                submissionData.append('image', formData.image);
            }
            const submissionData1 = new FormData();
            if (formData.image) {
                submissionData1.append('image', formData.image);
            }
            const cat = formData?.category?"1":"0"
            if(userId === 8){
                getUploadContentAgent(formData?.title, formData?.website, cat,submissionData1 )
            }else{
                getUploadContent(submissionData);
            }


        } else {
            snackbarUtil.error("Please fill in all required fields.");
        }
    };


    return (
        <div className="page-content">
            <Container fluid>
                <div className="position-relative mb-2 d-flex justify-content-between align-items-center">
                    <BreadCrumb title="Banner Setting" pageTitle="Dashboard" back />
                    <Button variant="subtle-dark" onClick={toggleBanner}>
                        <i className="fs-lg align-middle ri-add-fill"></i> Add New
                    </Button>
                </div>

                <Card>
                    <Card.Body>
                        <BannerSettingTable getDeleted={getDeleted} bannerData={bannerData} />
                    </Card.Body>
                </Card>
            </Container>

            <Modal show={showBanner} onHide={toggleBanner} className="zoomIn" scrollable>
                <Modal.Header className="modal-title fw-bold pb-2">
                    <div>Upload Banner</div>
                    <Button variant="light btn-sm" onClick={toggleBanner}>
                        <i className="ri-close-line fs-xl align-middle"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body className="pt-1">
                    {
                        userId === 8 && <div>
                        <Form.Label>Type</Form.Label>
                        <Select
                            name="content_type"
                            isClearable
                            className={`customSelect ${errors.type ? "is-invalid" : ""}`}
                            options={optionData || []}
                            onChange={(selected: string) => handleChange('type', selected)}
                        />
                        
                    </div>
                    }
                    
                    <div className="mt-2">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            name="title"
                            type="text"
                            value={formData.title}
                            onChange={(e) => handleChange('title', e.target.value)}
                            isInvalid={errors.title}
                        />
                        {errors.title && (
                            <Form.Control.Feedback type="invalid">
                                Title is required.
                            </Form.Control.Feedback>
                        )}
                    </div>
                    <div className="mt-2">
                        <Form.Label>Website</Form.Label>
                        <Form.Control
                            name="website"
                            type="text"
                            value={formData.website}
                            onChange={(e) => handleChange('website', e.target.value)}
                            isInvalid={errors.website}
                        />
                        {errors.website && (
                            <Form.Control.Feedback type="invalid">
                                Website is required.
                            </Form.Control.Feedback>
                        )}
                    </div>
                    {
                        userId === 8 && <div className="mt-3">
                            <Form.Label>Select Device</Form.Label>
                            <div className="d-flex">
                                <Form.Check
                                    type="radio"
                                    name="device"
                                    id="desktop"
                                    label="Desktop"
                                    checked={formData.device === "desktop"}
                                    onChange={() => handleChange('device', "desktop")}

                                />
                                <Form.Check
                                    type="radio"
                                    name="device"
                                    id="mobile"
                                    label="Mobile"
                                    checked={formData.device === "mobile"}
                                    onChange={() => handleChange('device', "mobile")}
                                    isInvalid={errors.device}
                                    className="ms-3"
                                />
                            </div>
                            {errors.device && (
                                <div className="invalid-feedback d-block">Please select a device.</div>
                            )}
                        </div>
                    }

                    <div className="mt-2">
                        <Form.Label>Image</Form.Label>
                        <Form.Control
                            name="image"
                            type="file"
                            onChange={(e: any) => handleChange('image', e.target.files[0])}
                            isInvalid={errors.image}
                        />
                        {errors.image && (
                            <Form.Control.Feedback type="invalid">
                                Please upload an image.
                            </Form.Control.Feedback>
                        )}
                    </div>
                    {
                        userId === 8 && <div className="mt-3 d-flex align-items-center">
                            <Form.Label>Category</Form.Label>
                            <Form.Check
                                type="checkbox"
                                id="Category"
                                checked={formData.category}
                                onChange={(e) => handleChange('category', e.target.checked)}
                                className="ms-2"
                            />
                        </div>
                    }

                </Modal.Body>
                <Modal.Footer className="border-top">
                    <Button onClick={handleSubmit}>Update</Button>
                    <Button variant="subtle-dark" onClick={toggleBanner}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default BannerSetting;
