import TableContainer from "Common/TableContainer";
import { useMemo, useState, } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import snackbarUtil from 'Utils/snackBarUtil';
import image from '../../assets/images/auth/maintenance.png'

interface Props {
  bannerData: bannerPayload[]
  getDeleted: (id: string) => Promise<void>
}

const DepositTable = ({ }) => {
  const [showImage, setImage] = useState(false);
  const [showSetting, setSetting] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showRemark, setRemark] = useState('');

  const toggleImage = () => {
    setImage(!showImage);
  };
  const toggleSetting = () => {
    setSetting(!showSetting);
  };
  const toggleRemark = (label:any) => {
    setRemark(label)
  };

  const handleCopy = () => {
    setCopied(true);
    snackbarUtil.success("Copy Successfull")
    setTimeout(() => {
        setCopied(false);
    }, 3000);
  };

  const deposit = [
    {'username': 'testice2', 'type': 'Deposit', 'utr': '1234152315412', 'tDate': '18-10-2024 10:27:26 AM', 'rAmount': '150', 'crypto': 'Crypto', 'bankName': 'HDFC', 'holderName': 'K.Sanjayku	', 'accountNo': '33288990684', 'status': 'Pending', 'updatedTime': '1 days, 1 hours, 36 Min 38 Sec',},
    {'Username': 'testice2', 'Type': 'Deposit', 'utr': '1234152315412', 'tDate': '18-10-2024 10:27:26 AM', 'rAmount': '150', 'crypto': 'Crypto', 'bankName': 'HDFC', 'holderName': 'K.Sanjayku	', 'accountNo': '33288990684', 'status': 'Pending', 'updatedTime': '1 days, 1 hours, 36 Min 38 Sec',},
    {'Username': 'testice2', 'Type': 'Deposit', 'utr': '1234152315412', 'tDate': '18-10-2024 10:27:26 AM', 'rAmount': '445', 'crypto': 'Crypto', 'bankName': 'HDFC', 'holderName': 'K.Sanjayku	', 'accountNo': '33288990684', 'status': 'Pending', 'updatedTime': '1 days, 1 hours, 36 Min 38 Sec',},
  ]

  const columns = useMemo(
    () => [
      {
        header: "Username",
        accessorKey: "username",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <>
              <span>Testic</span>
              <CopyToClipboard text={"testic"} onCopy={handleCopy}>
                <i className='ms-1 align-middle ri-file-copy-line cursor-pointer'></i>
              </CopyToClipboard>
            </>
          );
        },
      },
      {
        header: "Type",
        accessorKey: "type",
        enableColumnFilter: false,
      },
      {
        header: "UTR No.",
        accessorKey: "utr",
        enableColumnFilter: false,
      },
      {
        header: "Transaction Date",
        accessorKey: "tDate",
        enableColumnFilter: false,
      },
      {
        header: "Request Amount",
        accessorKey: "rAmount",
        enableColumnFilter: false,
      },
      {
        header: "Crypto",
        accessorKey: "crypto",
        enableColumnFilter: false,
      },
      {
        header: "Bank Name",
        accessorKey: "bankName",
        enableColumnFilter: false,
      },
      {
        header: "Holder Name",
        accessorKey: "holderName",
        enableColumnFilter: false,
      },
      {
        header: "Account No.",
        accessorKey: "accountNo",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
      },
      {
        header: "Updated Time",
        accessorKey: "updatedTime",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        accessorKey: "action",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <Button className="btn-sm" variant="subtle-primary" onClick={toggleImage}><i className="fs-md align-middle ri-image-line"></i></Button>
              <Button className="btn-sm ms-2" variant="subtle-dark" onClick={toggleSetting}><i className="fs-md align-middle ri-settings-4-line"></i></Button>
              <CopyToClipboard text={'Bank Details'} onCopy={handleCopy}>
                <Button className="btn-sm ms-2" variant="subtle-info"><i className="fs-md align-middle ri-file-copy-line"></i></Button>
              </CopyToClipboard>
            </div>
          )
        },
      },
    ],
    []
  );

  return (
    <>
      <TableContainer
        columns={columns || []}
        data={deposit || []}
        isBordered={false}
        customPageSize={50}
        isPagination
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
      />

      <Modal show={showImage} onHide={toggleImage} className="zoomIn" scrollable>
        <Modal.Header className="modal-title fw-bold pb-2">
          <div>Screen Shot</div>
          <Button variant="light btn-sm" onClick={toggleImage}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="pt-1">
         <img src={image} alt="Image" className="w-100" />
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button variant="subtle-dark" onClick={toggleImage}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSetting} onHide={toggleSetting} className="zoomIn" scrollable>
        <Modal.Header className="modal-title fw-bold">
          Username
          <Button variant="light btn-sm" onClick={toggleSetting}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <Button variant="subtle-success" className="d-flex flex-column py-3 w-25 mx-3 align-items-center" onClick={()=>toggleRemark('Accept')}>
              <i className="ri-check-line align-middle" style={{fontSize: '30px'}}></i>
              <span className="fs-xl">Accept</span>
            </Button>
            <Button variant="subtle-danger" className="d-flex flex-column py-3 w-25 mx-3 align-items-center" onClick={()=>toggleRemark('Reject')}>
              <i className="ri-close-line align-middle" style={{fontSize: '30px'}}></i>
              <span className="fs-xl">Reject</span>
            </Button>
          </div>
          <div className="mt-3 mx-auto w-75">
            {showRemark === 'Accept' && (
              <div className="input-group">
                <div className="input-group-text">UTR No.</div>
                <Form.Control type="number" value={9876543215568}/>
              </div>
            )}
            {showRemark === 'Reject' && (
              <div className="input-group">
                <div className="input-group-text">Remark</div>
                <Form.Select>
                  <option selected>Please Select</option>
                  <option value="Tech Glitch">Tech Glitch</option>
                  <option value="Reference Number Missing">Reference Number Missing</option>
                  <option value="Edited Payment Slip">Edited Payment Slip</option>
                  <option value="Invalid Bank">Invalid Bank</option>
                  <option value="Payment Late Attached / Old Payment">Payment Late Attached / Old Payment</option>
                  <option value="Payment Not Updated">Payment Not Updated</option>
                  <option value="Used in Same User">Used in Same User</option>
                  <option value="Used in Other User/Site">Used in Other User/Site</option>
                  <option value="UTR & Account Didn't Match">UTR & Account Didn't Match</option>
                  <option value="Insufficient Balance">Insufficient Balance</option>
                </Form.Select>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button>Update</Button>
          <Button variant="subtle-dark" onClick={toggleSetting}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DepositTable;
