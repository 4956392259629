import CasinoBetsTable from "Common/Tables/CasinoBetsTable";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useEffect, useMemo, useRef, useState } from "react";
import debounce from "lodash.debounce";
import { authServices } from "Utils/auth/services";
import { getLastTimeOfDay, getStartTimeOfDay } from "Utils/datefilter";
import { generateOptions } from "Utils/searchFilter";
import { useNavigationType } from "react-router-dom";
import Loader from "Common/Loader";

interface StatementProps {
  filter?: boolean;
  activeTab: string;
}

const CasinoBets = ({ filter, activeTab }: StatementProps) => {
  const currentDate = new Date();
  const sevenDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 7));

  const [startDate, setStartDate] = useState<string>(
    getStartTimeOfDay(sevenDaysAgo)
  );
  const [endDate, setEndDate] = useState<string>(getLastTimeOfDay(new Date()));
  const [betType, setBetType] = useState<string>("open");
  const [casinoBetsData, setCasinoBetsData] = useState<CasinoBetsData[]>([]);
  const [usersData, setUsersData] = useState<SearchUsersListData[]>([]);
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [inputValue, setInputValue] = useState("");
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userOptions = useMemo(
    () => generateOptions(usersData || [], "_id", "user_name"),
    [usersData]
  );

  const navigationType = useNavigationType();
  const isInitialRender = useRef(true);
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    localStorage.setItem("casinoStart", startDate);
    localStorage.setItem("casinoEnd", endDate);
  }, [startDate, endDate]);

  const getCasinoBetsList = async (filters: any = {}) => {
    setIsLoading(true);
    const { response } = await authServices.getCasinoBets({
      limit: limit,
      page: page,
      ...filters,
    });
    setIsLoading(false);
    if (response?.data && response?.data?.data) {
      setCasinoBetsData(response?.data?.data);
      setListTotal({
        total: response?.data?.metadata?.totalPages,
        page: response?.data?.metadata?.currentPage,
      });
    } else {
      setCasinoBetsData([]);
      setListTotal({
        total: 0,
        page: 1,
      });
    }
  };

  const getUsresList = async (userName: string) => {
    const { response } = await authServices.getUserList({
      user_name: userName,
    });
    if (response?.data) {
      setUsersData(response?.data);
    } else {
      setUsersData([]);
    }
  };

  const fetchOptions = debounce(async (input: string) => {
    if (input.length >= 3) {
      getUsresList(input);
    }
  }, 300);

  useEffect(() => {
    fetchOptions(inputValue);
  }, [inputValue]);

  useEffect(() => {
    let start =
      navigationType === "POP"
        ? localStorage.getItem("casinoStart")
        : startDate;
    let end =
      navigationType === "POP" ? localStorage.getItem("casinoEnd") : endDate;
    start && setStartDate(start);
    end && setEndDate(end);

    activeTab === "CasinoBets" &&
      getCasinoBetsList({
        bets_type: betType,
        from_date: start,
        to_date: end,
      });
  }, [activeTab]);

  const handleChange = (e: any) => {
    setBetType(e.target.value);
    getCasinoBetsList({
      from_date: startDate,
      to_date: endDate,
      bets_type: e.target.value,
    });
  };

  const handlefilterSubmit = () => {
    let filterData : {
      from_date: string;
      to_date: string;
      bets_type: string;
      user_id?: string;
    } = {
      from_date: startDate,
      to_date: endDate,
      bets_type: betType,
    };

    if (selectedUser) filterData.user_id = selectedUser;

    getCasinoBetsList(filterData);
  };

  const handlePageFilter = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);
    let filterData : {
      from_date: string;
      to_date: string;
      bets_type: string;
      page: number;
      limit: number;
      user_id?: string;
    } = {
      from_date: startDate,
      to_date: endDate,
      bets_type: betType,
      page: page,
      limit: limit,
    };

    if (selectedUser) filterData.user_id = selectedUser;

    getCasinoBetsList(filterData);
  };

  const clearfilterSubmit = () => {
    setSelectedUser('');
    setStartDate(getStartTimeOfDay(sevenDaysAgo));
    setEndDate(getLastTimeOfDay(new Date()));
    setBetType('open');
    setUsersData([]);

    let filterData : {
      from_date: string;
      to_date: string;
      bets_type: string;
    } = {
      from_date: getStartTimeOfDay(sevenDaysAgo),
      to_date: getLastTimeOfDay(new Date()),
      bets_type: 'open',
    };

    getCasinoBetsList(filterData);
  };

  return (
    <>
      {filter ? (
        <Card>
          <Card.Body>
            <Form>
              <Row className="gy-3 align-items-end">
                <Col lg={9}>
                  <Row className="gap-3 gap-sm-0">
                    <Col lg={3}>
                      <Form.Label>Start Date</Form.Label>
                      <Flatpickr
                        className="form-control"
                        pl
                        options={{
                          enableTime: false,
                          dateFormat: "Y-m-d",
                          defaultDate: [startDate],
                          maxDate: endDate,
                        }}
                        value={startDate}
                        onChange={([date]: [Date]) => {
                          setStartDate(getStartTimeOfDay(new Date(date)));
                        }}
                      />
                    </Col>
                    <Col lg={3}>
                      <Form.Label>End Date</Form.Label>
                      <Flatpickr
                        className="form-control"
                        pl
                        options={{
                          enableTime: false,
                          dateFormat: "Y-m-d",
                          defaultDate: [endDate],
                          maxDate: getLastTimeOfDay(new Date()),
                        }}
                        value={endDate}
                        onChange={([date]: [Date]) => {
                          setEndDate(getLastTimeOfDay(new Date(date)));
                        }}
                      />
                    </Col>
                    <Col lg={3}>
                      <Form.Label>Bet Type</Form.Label>
                      <Form.Select
                        id="floatingSelect"
                        aria-label="Floating label select example"
                        value={betType}
                        onChange={handleChange}
                      >
                        <option selected>Choose...</option>
                        <option value="open">Open</option>
                        <option value="settled">Settle</option>
                        <option value="cancelled">Close</option>
                      </Form.Select>
                    </Col>
                    <Col lg={3}>
                      <Form.Label>Search By Username</Form.Label>
                      <Select
                        isClearable
                        options={userOptions}
                        value={
                          userOptions.find(
                            (option: any) => option.value === selectedUser
                          ) || null
                        }
                        className="customSelect"
                        onInputChange={(input: any) => setInputValue(input)}
                        onChange={(e: any) => {
                          if (e) {
                            setSelectedUser(e.value);
                          } else {
                            setSelectedUser("");
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={3}>
                  <Button
                    className="me-2 btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handlefilterSubmit()}
                  >
                    <i className="fs-lg align-middle ri-filter-line"></i> Submit
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="btn-sm"
                    style={{ height: "38px" }}
                    onClick={()=>clearfilterSubmit()}
                  >
                    <i className="fs-lg align-middle ri-refresh-line"></i> Clear
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      ) : null}
      <Card>
        <Card.Body>
          <CasinoBetsTable
            casinoBetsData={casinoBetsData}
            page={page}
            limit={limit}
            listTotal={listTotal}
            handlePageFilter={handlePageFilter}
          />
        </Card.Body>
      </Card>
      {isLoading && <Loader />}
    </>
  );
};

export default CasinoBets;
