import { ListPagination } from "Common/ListPagination";
import BetListModal from "Common/Modals/BetListModal";
import MarketPositionModal from "Common/Modals/MarketPositionModal";
import TableContainer from "Common/TableContainer";
import TableEntry from "Common/TableEntry";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Button, Dropdown, Form, Modal, Table } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import { settingServices } from "Utils/setting/services";
import snackbarUtil from "Utils/snackBarUtil";

const FancyResultTable = ({}) => {
  const [showResult, setResult] = useState(false);
  const [showAbonded, setAbonded] = useState(false);
  const [showBook, setBook] = useState(false);
  const [showBet, setBet] = useState(false);
  const [fancyResult, setFancyResult] = useState<fancyResultRes[]>([]);
  const [fancyBook, setFancyBook] = useState<fancyPositionBook[]>([]);
  const [fancyID, setFancyId] = useState<string>("");
  const [matchedId, setMatchedId] = useState<string>("");
  const [showNews, setNews] = useState(false);
  const [newsValues, setNewsValues] = useState<string>("");
  const [matchName, setMatchName] = useState<string>("");
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState<number>(1);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 1,
    page: 1,
  });
  const [resultPayload, setResultPayload] = useState({
    sport_id: "",
    sport_name: "",
    series_id: "",
    series_name: "",
    match_id: "",
    match_name: "",
    fancy_id: "",
    fancy_name: "",
    match_date: "",
    result: "",
  });
  const [resultValue, setResultValue] = useState<{ [key: string]: string }>({});
  const [error, setError] = useState("");

  const toggleResult = (
    sport_id: string,
    sport_name: string,
    series_id: string,
    series_name: string,
    match_id: string,
    match_name: string,
    fancy_id: string,
    fancy_name: string,
    match_date: string
  ) => {
    setResultPayload((prev) => ({
      ...prev,
      sport_id,
      sport_name,
      series_id,
      series_name,
      match_id,
      match_name,
      fancy_id,
      fancy_name,
      match_date,
    }));
    setResult(true);
  };

  const toggleAbonded = (id: string) => {
    setFancyId(id);
    setAbonded(!showAbonded);
  };
  const toggleBook = (id: string) => {
    getFancyPosition(id);
    setBook(!showBook);
  };
  const toggleBet = (id: string, matchId: string, name: string) => {
    setFancyId(id);
    setMatchedId(matchId);
    setMatchName(name);
    setBet(!showBet);
  };
  const toggleNews = (id: string) => {
    setFancyId(id);
    setNews(!showNews);
  };

  const getFancyResult = async (filters: any = {}) => {
    const { response } = await settingServices.getFancyResult({
      limit: limit,
      page: page,
      search: {
        is_result_declared: 0,
      },
      ...filters,
    });
    setFancyResult(response?.data?.data);
    setListTotal({
      total: response?.data?.metadata[0]
        ? response?.data?.metadata[0]?.total / (filters.limit || limit)
        : 1,
      page: response?.data?.metadata[0] ? response?.data?.metadata[0]?.page : 0,
    });
  };

  const getSessionResult = async (data: any) => {
    const { response } = await settingServices.getSessionResult(data);
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      setResult(false);
      setResultValue({});
    } else {
      snackbarUtil.error(response?.msg);
    }
  };
  const userDetails = localStorage.getItem("adminDetails");

  const userId = userDetails && JSON.parse(userDetails)?.user_id;

  const updateFancyStatus = async (fancy_id: string, is_active: string) => {
    const { response } = await authServices.updateFancyStatus({
      fancy_id,
      is_active,
      user_id: userId,
    });

    if (response?.status) {
      snackbarUtil.success(response?.msg);
      getFancyResult();
    } else {
      snackbarUtil.error(response?.msg);
    }
  };
  const getSessionAbd = async (fancy_id: string) => {
    const { response } = await settingServices.getSessionAbd({
      fancy_id,
    });
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      setAbonded(false);
    } else {
      snackbarUtil.error(response?.msg);
    }
  };

  const handleUpdateAbd = () => {
    getSessionAbd(fancyID);
  };

  useEffect(() => {
    getFancyResult();
  }, []);

  const handlePageFilter = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);

    getFancyResult({
      page: page,
      limit: limit,
    });
  };

  const handleUpdateResult = (fancy_id: string) => {
    const value = resultValue[fancy_id];
    if (!value) {
      setError("Result cannot be empty");
      return;
    }

    const updatedPayload = { ...resultPayload, result: value };
    setError("");
    getSessionResult(updatedPayload);
    setResult(false);
  };

  const handleInputChange = (fancy_id: string, value: string) => {
    setResultValue((prev) => ({
      ...prev,
      [fancy_id]: value,
    }));
  };

  const getUpdateEventNews = async (data: any) => {
    const { response } = await authServices?.updateEvent({
      event: data?.eventName,
      filter: {
        [data?.sportName]: data?.sportId,
      },
      update: {
        news: newsValues,
      },
    });
    if (response?.status) {
      snackbarUtil.success(response?.data);
      setNews(false);
    } else {
      snackbarUtil.error(response?.msg);
    }
  };
  const getFancyPosition = async (fancy_id: string) => {
    const { response } = await settingServices?.getFancyPosition({
      fancy_id,
    });
    setFancyBook(response?.data?.fancy_position);
  };

  const handleUpdateNews = () => {
    const data = {
      eventName: "fancy",
      sportName: "fancy_id",
      sportId: fancyID,
    };
    getUpdateEventNews(data);
  };

  const toggleDaf = (id: string, is_active: number) => {
    const newVal = is_active === 0 ? "1" : "0";
    updateFancyStatus(id, newVal);
  };

  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "no",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return ((page - 1) * limit) + cell.row.index + 1;
        },
      },
      {
        header: "Match Date",
        accessorKey: "matchDate",
        enableColumnFilter: false,

        cell: (cell: any) => {
          return (
            <div>
              {moment(cell.row?.original?.createdAt).format(
                "DD-MM-YYYY, hh:mm:ss, A"
              )}
            </div>
          );
        },
      },
      {
        header: "Match Name",
        accessorKey: "match_name",
        enableColumnFilter: false,
      },
      {
        header: "Market Name",
        accessorKey: "fancy_name",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const fancy_id = cell.row?.original?.fancy_id;
          return (
            <div className="d-flex align-items-center">
              <Form.Control
                name="result"
                type="number"
                className="form-control-sm me-2"
                style={{maxWidth: "99px", minWidth: '60px'}}
                id={fancy_id}
                value={resultValue[fancy_id]}
                onChange={(e) => handleInputChange(fancy_id, e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              />

              <div className="d-flx">
                <Button className="btn-sm me-1">Result</Button>
                <Button variant="subtle-danger" className="btn-sm me-1">Abonded</Button>
                <Button variant="info" className="btn-sm me-1">Book</Button>
                <Button variant="subtle-primary" className="btn-sm me-1">Bet</Button>
                <Button variant="subtle-dark" className="btn-sm me-1">News</Button>
              </div>

              {/* <Dropdown drop="start">
                <Dropdown.Toggle
                  variant="subtle-dark"
                  className="e-caret-hide btn-sm"
                >
                  <i className="ri-more-2-fill"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    className="border-bottom py-2"
                    onClick={() =>
                      toggleResult(
                        cell.row?.original?.sport_id,
                        cell.row?.original?.sport_name,
                        cell.row?.original?.series_id,
                        cell.row?.original?.series_name,
                        cell.row?.original?.match_id,
                        cell.row?.original?.match_name,
                        cell.row?.original?.fancy_id,
                        cell.row?.original?.fancy_name,
                        cell.row?.original?.match_date
                      )
                    }
                  >
                    Result
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="border-bottom py-2"
                    onClick={() =>
                      toggleDaf(
                        cell.row?.original?.fancy_id,
                        cell.row?.original?.is_active
                      )
                    }
                  >
                    D.A.F
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="border-bottom py-2"
                    onClick={() => toggleAbonded(cell.row?.original?.fancy_id)}
                  >
                    Abonded
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="border-bottom py-2"
                    onClick={() => toggleBook(cell.row?.original?.fancy_id)}
                  >
                    Book
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="border-bottom py-2"
                    onClick={() =>
                      toggleBet(
                        cell.row?.original?.fancy_id,
                        cell.row?.original?.match_id,
                        cell?.row.original.match_name
                      )
                    }
                  >
                    Bet
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="border-bottom py-2"
                    onClick={() => toggleNews(cell.row?.original?.fancy_id)}
                  >
                    News
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </div>
          );
        },
      },
    ],
    [resultValue, page, limit]
  );
  return (
    <>
      <TableContainer
        columns={columns || []}
        data={fancyResult || []}
        isBordered={false}
        customPageSize={limit}
        isPagination={false}
        sorting={true}
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
      />

      <nav aria-label="Page navigation" className="px-3 d-flex flex-column flex-sm-row align-items-center">
        <TableEntry
          limit={limit}
          handlelimitFilter={(value: number) => handlePageFilter(page, value)}
        />
        <ListPagination
          listTotal={listTotal}
          handlePageFilter={(value: number) => handlePageFilter(value, limit)}
        />
      </nav>

      <Modal
        show={showResult}
        onHide={() => setResult(false)}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <span>Update Result</span>
          <Button variant="light btn-sm" onClick={() => setResult(false)}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="fs-md">
          <p className="fs-lg">
            Are you sure you want to update <strong>Result</strong>?
          </p>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button
            className="ms-2"
            onClick={() => handleUpdateResult(resultPayload.fancy_id)}
          >
            Update
          </Button>
          <Button variant="light" onClick={() => setResult(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAbonded}
        onHide={() => toggleAbonded("")}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <span>Match Abond</span>
          <Button variant="light btn-sm" onClick={() => toggleAbonded("")}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="fs-md">
          <p className="fs-lg">
            Are you sure you want to update <strong>Abonded</strong>?
          </p>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button className="ms-2" onClick={handleUpdateAbd}>
            Update
          </Button>
          <Button variant="light" onClick={() => toggleAbonded("")}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showBook}
        onHide={() => setBook(false)}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <span>Fancy Position</span>
          <Button variant="light btn-sm" onClick={() => setBook(false)}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="fs-md">
          <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead>
                <tr>
                  <th scope="col">Run</th>
                  <th scope="col">P&L</th>
                </tr>
              </thead>
              <tbody>
                {fancyBook?.map((items) => {
                  return (
                    <tr>
                      <td>{items?.key}</td>
                      <td
                        className={
                          Number(items?.value) > 0
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        {items?.value}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>

      <BetListModal
        matchName={matchName}
        name="fancy_id"
        marketid={fancyID}
        matchedId={matchedId}
        show={showBet}
        clickHandler={toggleBet}
        key={""}
      />

      <Modal
        show={showNews}
        onHide={() => toggleNews("")}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <span>Update News</span>
          <Button variant="light btn-sm" onClick={() => toggleNews("")}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="fs-md">
          <div className="form-floating">
            <Form.Control
              onChange={(e) => setNewsValues(e.target.value)}
              value={newsValues}
              as="textarea"
              rows={5}
              id="Content"
              style={{ height: "auto" }}
              placeholder="Content"
            />
            <Form.Label htmlFor="Content">Content</Form.Label>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button variant="light" onClick={() => toggleNews("")}>
            Cancel
          </Button>
          <Button className="ms-2" onClick={handleUpdateNews}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FancyResultTable;
