import BreadCrumb from "Common/BreadCrumb";
import FancyResultTable from "Common/Tables/FancyResultTable";
import { useState } from "react";
import { Container, Button, Card, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";

const options = [
    { value: "Sport", label: "Sport Name" },
    { value: "Series", label: "Series Name" },
    { value: "Match", label: "Match Name" },
    { value: "Market", label: "Market Name" },
];
  
const FancyResult = () => {
    const[showFilter, setFilter] = useState(false)

    return (
        <div className="page-content">
            <Container fluid>
                <div className="position-relative mb-2 d-flex justify-content-between align-items-center">
                    <BreadCrumb title="Fancy Result" pageTitle="Dashboard" back />
                    {/* <Button variant="subtle-dark" onClick={()=>setFilter(!showFilter)}><i className="fs-lg align-middle ri-filter-3-line"></i> Filter</Button> */}
                </div>

                {/* {showFilter ? (
                    <Card>
                        <Card.Body>
                            <Form>
                                <Row className="gy-3 align-items-end">
                                    <Col lg={9}>
                                        <Row className="gap-3 gap-sm-0">
                                            <Col lg={3}>
                                                <Form.Label>Search Sport Name</Form.Label>
                                                <Select isClearable options={options} className="customSelect" />
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Label>Search Series Name</Form.Label>
                                                <Select isClearable options={options} className="customSelect" />
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Label>Search Match Name</Form.Label>
                                                <Select isClearable options={options} className="customSelect" />
                                            </Col>
                                            <Col lg={3}>
                                                <Form.Label>Search Market Name</Form.Label>
                                                <Select isClearable options={options} className="customSelect" />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={3}>
                                        <Button className="me-2 btn-sm" style={{ height: "38px" }}>
                                            <i className="fs-lg align-middle ri-filter-line"></i> Submit
                                        </Button>
                                        <Button variant="outline-primary" className="me-2 btn-sm" style={{ height: "38px" }}>
                                        <i className="fs-lg align-middle ri-refresh-line"></i> Clear
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                ):null} */}

                <Card>
                    <Card.Body>
                        <FancyResultTable />
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
};

export default FancyResult;