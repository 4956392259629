import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface MarketTableProps {
  marketData: MarketData[];
}

const MarketTable = ({ marketData }: MarketTableProps) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="table-responsive">
        <Table className="table-centered align-middle table-nowrap table-striped">
          <thead className="table-light">
            <tr>
              <th>No.</th>
              <th>Sport Name</th>
              <th>Match Name</th>
              <th>Market Name</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {marketData?.map((item, index) => {
              if (item?.liabilitySum) return 
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    <div>
                      <p className="cursor-pointer text-info">
                        {item?.sport_name}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div
                      onClick={() =>
                        navigate(
                          `/match-detail/${item?.match_id}/${item?.sport_id}/1`
                        )
                      }
                    >
                      <p className="cursor-pointer text-info">
                        {item?.match_name}
                      </p>
                    </div>
                  </td>
                  <td>
                    <span className="cursor-pointer text-info">
                      {item?.event_name}
                    </span>
                  </td>
                  <td>
                    <span
                      className={`cursor-pointer text-${
                        item?.liability < 0 ? "danger" : "success"
                      }`}
                    >
                      {item?.liability}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
          {marketData?.map((item, index) => {
              if (item?.liabilitySum)
                 return <tr>
              <th colSpan={3}></th>
              <th>Total</th>
              <th
                className={`text-${
                  item?.liabilitySum < 0 ? "danger" : "success"
                }`}
              >
                {item?.liabilitySum.toFixed(2) || 0}
              </th>
             
            </tr>
          })}
          </tfoot>
        </Table>
      </div>
    </>
  );
};

export default MarketTable;
