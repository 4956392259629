import BreadCrumb from "Common/BreadCrumb";
import Select from "react-select";
import { Container, Card, Col, Form, Row, Button } from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";
import { authServices } from "Utils/auth/services";
import { generateOptions } from "Utils/searchFilter";
import { useParams } from "react-router-dom";
import BetHistoryTable from "Common/Tables/BetHistoryTable";
import { debounce } from "lodash";
import Loader from "Common/Loader";

const BetsHistory = () => {
  const { matchid, marketid, type, userid } = useParams();
  const [betType, setBetType] = useState<string>("open");
  const [betsData, setBetsData] = useState<BetHistoryList[]>([]);
  const [searchList, setSearchList] = useState<SearchSelection[]>();
  const [selectedId, setSelectedId] = useState<string>("");
  const [odds, setOdds] = useState<string>("");
  const [stack, setStack] = useState<string>("");
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });
  const [userNameData, setUserNameData] = useState<any[]>([]);
  const [user_name, setUser_name] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const selectionOptions = useMemo(
    () => generateOptions(searchList || [], "selection_id", "selection_name"),
    [searchList]
  );

  const getUserbyUserName = async (user_name: string) => {
    const { response } = await authServices.getUserList({
      user_name,
    });
    const options = response?.data.map((user: any) => ({
      value: user?._id,
      label: user?.user_name,
    }));
    setUserNameData(options);
  };

  const handleUser = (inputValue: string) => {
    if (inputValue) {
      setInputValue(inputValue);
    }
  };

  const fetchOptions = debounce(async (input: string) => {
    if (input.length >= 3) {
      getUserbyUserName(input);
    }
  }, 300);

  useEffect(() => {
    fetchOptions(inputValue);
  }, [inputValue]);

  const handleSelectUser = (selectedOption: any) => {
    let filterData: SearchPayload = {};
    if (type && type == "2") {
      filterData.fancy_id = marketid;
    } else {
      filterData.market_id = marketid;
    }
    if (betType === "1" || betType === "0")
      filterData.is_back = Number(betType);
    if (Number(selectedId)) filterData.selection_id = Number(selectedId);
    if (Number(odds)) filterData.odds = Number(odds);
    if (Number(stack)) filterData.stack = Number(stack);
    if (userid && userid != "null") {
      filterData.user_id = userid;
    }
    if (selectedOption) filterData.user_id = selectedOption.value;

    geOpenBetsList({
      search: filterData,
    });
    if (selectedOption) {
      setUser_name(selectedOption.value);
    } else {
      setUser_name("");
    }
  };

  const geOpenBetsList = async (filters: any = {}) => {
    setIsLoading(true);
    const { response } = await authServices.getBetHistoryList({
      limit: limit,
      page: page,
      match_id: matchid,
      ...filters,
    });
    setIsLoading(false);
    if (response?.data && response?.data?.data) {
      setBetsData(response?.data?.data);
      setListTotal({
        total: response?.data?.metadata[0]
          ? response?.data?.metadata[0]?.total / (filters?.limit || limit)
          : 0,
        page: response?.data?.metadata[0]
          ? response?.data?.metadata[0]?.page
          : 0,
      });
    } else {
      setBetsData([]);
      setListTotal({
        total: 0,
        page: 1,
      });
    }
  };

  const getSportSearchList = async (filters: any = {}) => {
    const { response } = await authServices.getSportsSearchList({
      type: "viewBet",
      ...filters,
    });
    if (response?.data) {
      setSearchList(response?.data);
    }
  };

  useEffect(() => {
    let payload: SearchPayload = {};
    if (type && type == "2") {
      payload.fancy_id = marketid;
    } else {
      payload.market_id = marketid;
    }
    if (userid && userid != "null") {
      payload.user_id = userid;
    }

    geOpenBetsList({
      search: payload,
    });

    let searchPayload: SearchPayload = {};
    if (type && type == "2") {
      searchPayload.fancy_id = marketid;
    } else {
      searchPayload.market_id = marketid;
    }

    getSportSearchList({
      search: searchPayload,
    });
  }, []);

  const handleSearchFilter = (id: string) => {
    let payload: SearchPayload = {
      selection_id: Number(id),
    };
    if (type && type == "2") {
      payload.fancy_id = marketid;
    } else {
      payload.market_id = marketid;
    }

    if (betType === "1" || betType === "0") payload.is_back = Number(betType);
    if (Number(odds)) payload.odds = Number(odds);
    if (Number(stack)) payload.stack = Number(stack);
    if (userid && userid != "null") {
      payload.user_id = userid;
    }
    if (user_name) payload.user_id = user_name;

    setSelectedId(id);
    geOpenBetsList({
      search: payload,
    });
  };

  const handleClearSelection = () => {
    let payload: SearchPayload = {};
    if (type && type == "2") {
      payload.fancy_id = marketid;
    } else {
      payload.market_id = marketid;
    }
    if (userid && userid != "null") {
      payload.user_id = userid;
    }

    setSelectedId("");
    setBetType("");
    setOdds("");
    setStack("");
    setUser_name("");
    geOpenBetsList({
      search: payload,
    });
  };

  const handleChange = (e: any) => {
    let betType = e.target.value;
    setBetType(e.target.value);

    let payload: SearchPayload = {};
    if (type && type == "2") {
      payload.fancy_id = marketid;
    } else {
      payload.market_id = marketid;
    }

    if (betType === "1" || betType === "0") payload.is_back = Number(betType);
    if (Number(selectedId)) payload.selection_id = Number(selectedId);
    if (Number(odds)) payload.odds = Number(odds);
    if (Number(stack)) payload.stack = Number(stack);
    if (userid && userid != "null") {
      payload.user_id = userid;
    }

    geOpenBetsList({
      search: payload,
    });
  };

  const handleOddsStake = (e: any, type: string) => {
    if (!Number(e.target.value) && Number(e.target.value) !== 0) return;
    let value = Number(e.target.value);

    let payload: SearchPayload = {};
    if (type && type == "2") {
      payload.fancy_id = marketid;
    } else {
      payload.market_id = marketid;
    }
    if (betType === "1" || betType === "0") payload.is_back = Number(betType);
    if (Number(selectedId)) payload.selection_id = Number(selectedId);

    if (type === "odds") {
      setOdds(value.toString());
      if (Number(value) != 0) payload.odds = Number(value);
    } else {
      setStack(value.toString());
      if (Number(value) != 0) payload.stack = Number(value);
    }
    if (userid && userid != "null") {
      payload.user_id = userid;
    }

    geOpenBetsList({
      search: payload,
    });
  };

  const handlePageFilter = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);
    let payload: SearchPayload = {};
    if (type && type == "2") {
      payload.fancy_id = marketid;
    } else {
      payload.market_id = marketid;
    }
    if (userid && userid != "null") {
      payload.user_id = userid;
    }

    geOpenBetsList({
      search: payload,
      page: page,
      limit: limit,
    });
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div
            className="position-relative mb-2 d-flex justify-content-between align-items-center"
            style={{ minHeight: "43px" }}
          >
            <BreadCrumb title={"View Bets"} pageTitle="Dashboard" back />
          </div>
          <>
            <Card>
              <Card.Body>
                <BetHistoryTable
                  betHistoryData={betsData}
                  page={page}
                  limit={limit}
                  listTotal={listTotal}
                  handlePageFilter={handlePageFilter}
                  handleUser={handleUser}
                  userNameData={userNameData}
                  userName={user_name}
                  handleSelectUser={handleSelectUser}
                  setUser_name={setUser_name}
                  marketOptions={selectionOptions}
                  selectedMarketId={selectedId}
                  handleMarketFilter={handleSearchFilter}
                  handleClearMarket={handleClearSelection}
                  odds={odds}
                  stack={stack}
                  handleOddsStake={handleOddsStake}
                  betType={betType}
                  handleBetChange={handleChange}
                />
              </Card.Body>
            </Card>
          </>
        </Container>
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default BetsHistory;
