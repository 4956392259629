import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { Card, Col, Form, Row, Button } from "react-bootstrap";
import _, { debounce } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { authServices } from "Utils/auth/services";
import { useNavigationType, useParams } from "react-router-dom";
import { getLastTimeOfDay, getStartTimeOfDay } from "Utils/datefilter";
import SportsWisePLTable from "Common/Tables/SportWisePLTable";
import Loader from "Common/Loader";
import { generateOptions } from "Utils/searchFilter";

interface SportsPLProps {
  filter?: boolean;
  isCasino: boolean;
  activeTab: string;
}

interface Payload {
  from_date: string;
  to_date: string;
  user_id?: string;
  international_casinos?: number;
  search?: {
    casinoProvider?: string;
  };
}

const SportsPL = ({ filter, isCasino, activeTab }: SportsPLProps) => {
  const { userid } = useParams();
  const currentDate = new Date();
  const sevenDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 7));

  const [startDate, setStartDate] = useState<string>(
    getStartTimeOfDay(sevenDaysAgo)
  );
  const [endDate, setEndDate] = useState<string>(getLastTimeOfDay(new Date()));
  const [sports, setSports] = useState<SportsList[]>([]);
  const [sportsData, setSportsData] = useState<SportWisePlList[]>([]);
  const [parentId, setParentId] = useState<string>("null");
  const [usersData, setUsersData] = useState<SearchUsersListData[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userOptions = useMemo(
    () => generateOptions(usersData || [], "_id", "user_name"),
    [usersData]
  );

  const userId =
    userid && userid != "null" ? userid : localStorage.getItem("userId");

  const navigationType = useNavigationType();
  const isInitialRender = useRef(true);
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    localStorage.setItem("sportPLStart", startDate);
    localStorage.setItem("sportPLEnd", endDate);
  }, [startDate, endDate]);

  const geOpenBetsList = async (filters: any = {}) => {
    setIsLoading(true);
    const { response } = await authServices.getSportWisePL({
      user_id: userId,
      ...filters,
    });
    setIsLoading(false);
    if (response.status == true) {
      let userPlList = response.data.users;
      userPlList = _.orderBy(
        userPlList,
        ["user_type_id", "user_name"],
        ["desc", "asc"]
      );
      let gameList = Object.keys(response.data.sports).map((data) => ({
        name: data,
        lower_name: "",
        total: 0,
      }));
      let a = {
        name: "Commission",
        lower_name: "",
        total: 0,
      };
      let b = {
        name: "Total With Commission",
        lower_name: "",
        total: 0,
      };
      let c = {
        name: "Total",
        lower_name: "",
        total: 0,
      };
      gameList.push(c);
      gameList.push(a);
      gameList.push(b);
      for (let i = 0; i < userPlList.length; i++) {
        for (let j = 0; j < gameList.length; j++) {
          gameList[j].lower_name = gameList[j].name.toLowerCase();
          gameList[j].lower_name = gameList[j].lower_name.replace(/ /g, "_");
          if (userPlList[i][gameList[j].name.toLowerCase()] == undefined) {
            userPlList[i][
              gameList[j].name.toLowerCase().replace(/ /g, "_")
            ] = 0;
          }
        }
      }
      for (let i = 0; i < userPlList.length; i++) {
        userPlList[i].total_with_commission =
          userPlList[i].total + userPlList[i].commission;
      }
      for (let i = 0; i < gameList.length; i++) {
        gameList[i].total = 0;
        for (let j = 0; j < userPlList.length; j++) {
          if (
            userPlList[j][gameList[i].lower_name] == undefined ||
            userPlList[j][gameList[i].lower_name] == null ||
            userPlList[j][gameList[i].lower_name] == ""
          ) {
            gameList[i].total = gameList[i].total + 0;
          } else {
            gameList[i].total =
              gameList[i].total + userPlList[j][gameList[i].lower_name];
          }
        }
      }

      setSportsData(userPlList);
      setParentId(response?.parent_id);
      setSports(gameList);
    } else {
      setSportsData([]);
      setParentId('');
      setSports([]);
    }
  };

  const getUsresList = async (userName: string) => {
    const { response } = await authServices.getUserList({
      user_name: userName,
    });
    if (response?.data) {
      setUsersData(response?.data);
    } else {
      setUsersData([]);
    }
  };

  const fetchOptions = debounce(async (input: string) => {
    if (input.length >= 3) {
      getUsresList(input);
    }
  }, 300);

  useEffect(() => {
    fetchOptions(inputValue);
  }, [inputValue]);

  useEffect(() => {
    let start =
      navigationType === "POP"
        ? localStorage.getItem("sportPLStart")
        : startDate;
    let end =
      navigationType === "POP" ? localStorage.getItem("sportPLEnd") : endDate;
    start && setStartDate(start);
    end && setEndDate(end);

    let payload: Payload = {
      from_date: start || "",
      to_date: end || "",
    };
    if (isCasino) {
      payload.international_casinos = 1;
      payload.search = { casinoProvider: "QT" };
    }

    if (activeTab === "SportsPL" && !isCasino) {
      geOpenBetsList(payload);
    }

    if (isCasino && activeTab === "QTechPL") {
      geOpenBetsList(payload);
    }
  }, [activeTab]);

  const handlefilterSubmit = () => {
    let payload: Payload = {
      from_date: startDate,
      to_date: endDate,
    };
    if (isCasino) {
      payload.international_casinos = 1;
      payload.search = { casinoProvider: "QT" };
    }
    if (selectedUser) payload.user_id = selectedUser;

    geOpenBetsList(payload);
  };

  const handleClearSelection = () => {
    let payload: Payload = {
      from_date: getStartTimeOfDay(sevenDaysAgo),
      to_date: getLastTimeOfDay(new Date()),
    };
    if (isCasino) {
      payload.international_casinos = 1;
      payload.search = { casinoProvider: "QT" };
    }

    geOpenBetsList(payload);
    setStartDate(getStartTimeOfDay(sevenDaysAgo));
    setEndDate(getLastTimeOfDay(new Date()));
    setUsersData([]);
    setSelectedUser("");
  };

  const handleUserDetail = (user: string) => {
    let payload: Payload = {
      from_date: startDate,
      to_date: endDate,
      user_id: user && user != "null" ? user : userId || "",
    };
    if (isCasino) {
      payload.international_casinos = 1;
      payload.search = { casinoProvider: "QT" };
    }

    geOpenBetsList(payload);
  };

  return (
    <>
      {filter ? (
        <Card>
          <Card.Body>
            <Form>
              <Row className="gy-3 align-items-end">
                <Col lg={3}>
                  <Form.Label>Start Date</Form.Label>
                  <Flatpickr
                    className="form-control"
                    pl
                    options={{
                      enableTime: false,
                      dateFormat: "Y-m-d",
                      defaultDate: [startDate],
                      maxDate: endDate,
                    }}
                    value={startDate}
                    onChange={([date]: [Date]) => {
                      setStartDate(getStartTimeOfDay(new Date(date)));
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>End Date</Form.Label>
                  <Flatpickr
                    className="form-control"
                    pl
                    options={{
                      enableTime: false,
                      dateFormat: "Y-m-d",
                      defaultDate: [endDate],
                      maxDate: getLastTimeOfDay(new Date()),
                    }}
                    value={endDate}
                    onChange={([date]: [Date]) => {
                      setEndDate(getLastTimeOfDay(new Date(date)));
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search User Name</Form.Label>
                  <Select
                    isClearable
                    options={userOptions}
                    value={
                      userOptions.find(
                        (option: any) => option.value === selectedUser
                      ) || null
                    }
                    className="customSelect"
                    onInputChange={(input: any) => setInputValue(input)}
                    onChange={(e: any) => {
                      if (e) {
                        setSelectedUser(e.value);
                      } else {
                        setSelectedUser("");
                      }
                    }}
                  />
                </Col>

                <Col lg={3}>
                  <Button
                    className="me-2 btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handlefilterSubmit()}
                  >
                    <i className="fs-lg align-middle ri-filter-line"></i> Submit
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handleClearSelection()}
                  >
                    <i className="fs-lg align-middle ri-refresh-line"></i> Clear
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      ) : null}
      <Card>
        <Card.Body>
          <SportsWisePLTable
            sportsPLData={sportsData}
            sports={sports}
            parentId={parentId}
            handleUserDetail={handleUserDetail}
            isEvent={false}
          />
        </Card.Body>
      </Card>
      {isLoading && <Loader />}
    </>
  );
};

export default SportsPL;
