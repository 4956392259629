import { Form, Row, Col } from "react-bootstrap";
import ToggleSwitch from "./ToggleSwitch";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";

interface ToggleStates {
    [key: string]: boolean;
}

interface Props {
    userData: SportsPermission[] | undefined
    sportsPermission: SportsPermission[],
    setSportsPermission: Dispatch<SetStateAction<SportsPermission[]>>

    
}

const Permissions: FC<Props> = ({ userData, sportsPermission, setSportsPermission }) => {
    const [showToggle, setToggle] = useState<ToggleStates>({});
    

    useEffect(() => {
        if (userData) {
            const initialToggleStates: ToggleStates = {};
            userData.forEach((item) => {
                initialToggleStates[item.name] = item.is_allow; // Initialize state based on initial permissions
            });
            setToggle(initialToggleStates);
            setSportsPermission(userData);
        }
    }, [userData]);

    const handleToggleChange = (name: string) => {
        setToggle((prevState) => {
            const newState = { ...prevState, [name]: !prevState[name] };
            const newUserData = sportsPermission.map((item) =>
                item.name === name ? { ...item, is_allow: !prevState[name] } : item
            );

            setSportsPermission(newUserData);            
            return newState;
        });
    };


    return (
        <Form action="#">
            <Row className="g-3">
                <h5 className='text-primary mb-0'>Permissions</h5>
                {
                    userData?.map((items) => {
                        return (
                            <Col xs={6} sm={4}>
                                <div className='d-flex align-items-center'>
                                    <ToggleSwitch On='On' Off='Off' id={items.name}
                                onChange={() => handleToggleChange(items.name)}
                                checked={showToggle[items.name]}/>
                                    <Form.Label className="form-check-label ms-2" htmlFor="Cricket">{items?.name}</Form.Label>
                                </div>
                            </Col>
                        )
                    })
                }
            </Row>
        </Form>

    );
};

export default Permissions;