import { Button, Modal, Table } from "react-bootstrap";
import moment from "moment";

interface BookModalProps {
  show: any;
  clickHandler: any;
  chipHistoryData: ChipHistoryData[];
  chipHistoryText: string;
}

const HistoryModal = ({
  show,
  clickHandler,
  chipHistoryData,
  chipHistoryText,
}: BookModalProps) => {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={clickHandler}
      className="zoomIn"
      scrollable
    >
      <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-2">
        <div className="d-flex align-items-center">History</div>
        <Button variant="light btn-sm" onClick={clickHandler}>
          <i className="ri-close-line fs-xl align-middle"></i>
        </Button>
      </Modal.Header>
      <Modal.Body className="pt-2 fs-md">
        <div className="table-responsive">
          <Table className="align-middle table-striped table-nowrap mb-0">
            <thead className="table-light">
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Amount</th>
                <th scope="col">Updated Amount</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              {chipHistoryData.map((item) => {
                return (
                  <tr>
                    <td>{moment(item?.createdAt).format("DD-MM-YY")}</td>
                    <td>{item?.amount}</td>
                    <td>{item?.updated_balance}</td>
                    <td>{item?.comment}</td>
                  </tr>
                );
              })}
              {/* <tr>
                                <td>Date</td>
                                <td>Amount</td>
                                <td>Updated Amount</td>
                                <td>Description</td>
                            </tr>
                            <tr>
                                <td>Date</td>
                                <td>Amount</td>
                                <td>Updated Amount</td>
                                <td>Description</td>
                            </tr>
                            <tr>
                                <td>Date</td>
                                <td>Amount</td>
                                <td>Updated Amount</td>
                                <td>Description</td>
                            </tr> */}
            </tbody>
          </Table>
          {chipHistoryData?.length <= 0 && (
            <div className="d-flex align-items-center justify-content-center">
              {chipHistoryText}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HistoryModal;
