import { ListPagination } from "Common/ListPagination";
import TableContainer from "Common/TableContainer";
import TableEntry from "Common/TableEntry";
import moment from "moment";
import { useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { settingServices } from "Utils/setting/services";
import snackbarUtil from "Utils/snackBarUtil";

interface Props {
  betData: lotusBetRes[],
  getLotusBets: any,
  startDate: string, 
  endDate: string,   
  selectedUserId: string;
  page: number;
  limit: number;
  listTotal: ProfitLossTotal;
  handlePageFilter: (value: number, limit: number) => void;
}

const CasinoResultTable = ({ betData, page,
  limit,
  listTotal,
  handlePageFilter, }: Props) => {
  const [showList, setList] = useState(false);
  const [userName, setUserName] = useState<string>("");

  const toggleList = (id: string, name: string) => {
    getShowAgent(id);
    setUserName(name);
    setList(!showList);
  };

  const getRoundStatus = async (objectId: string) => {
    const { response } = await settingServices.getRoundStatus({ objectId });
    if(response){
      if (response?.status) {
        snackbarUtil.success(response?.msg);
      }else{
        snackbarUtil.error(response?.msg);
    }
    }
   
  };

  const getShowAgent = async (user_id: string) => {
    const { response } = await settingServices.getShowAgent({ user_id });
  };

  const handleStatusUpdate = (id: string) => {
    getRoundStatus(id);
  };

  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "no",
        enableColumnFilter: false,
        cell: (cell: any) => ((page - 1) * limit) + cell.row.index + 1,
      },
      {
        header: "Date",
        accessorKey: "date",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div>
            <p className="mb-0">{moment(cell?.row?.original?.createdAt).format("DD-MM-YYYY HH:mm:ss A")}</p>
          </div>
        ),
      },
      {
        header: "Domain",
        accessorKey: "domainName",
        enableColumnFilter: false,
        cell: (cell: any) => <span>{cell?.row?.original?.domainName}</span>,
      },
      {
        header: "User Name",
        accessorKey: "username",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <span
            className="cursor-pointer text-info"
            onClick={() => toggleList(cell?.row?.original?._id, cell?.row?.original?.userName)}
          >
            {cell?.row?.original?.userName}
          </span>
        ),
      },
      {
        header: "Market | Match",
        accessorKey: "matchMarket",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div>
            <p className="mb-0"><strong>{cell?.row?.original?.marketName}</strong></p>
            <p className="mb-0 text-muted">{cell?.row?.original?.matchName}</p>
          </div>
        ),
      },
      {
        header: "Selection | Type",
        accessorKey: "selectionType",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div>
            <p className="mb-0"><strong>{cell?.row?.original?.runnerName}</strong></p>
            <p className={`mb-0 ${cell?.row?.original?.isBack ? "btn-back" : "btn-lay"} d-inline px-2 rounded-pill`}>
              {cell?.row?.original?.isBack ? "Back" : "Lay"}
            </p>
          </div>
        ),
      },
      {
        header: "Rate",
        accessorKey: "rate",
        enableColumnFilter: false,
        cell: (cell: any) => <span>{cell?.row?.original?.odds}</span>,
      },
      {
        header: "Stake",
        accessorKey: "stake",
        enableColumnFilter: false,
        cell: (cell: any) => <span>{cell?.row?.original?.stake}</span>,
      },
      {
        header: "P | L",
        accessorKey: "profitLoss",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const pnl = cell?.row?.original?.pnl;
          return (
            <span className={`cursor-pointer ${pnl > 0 ? "text-success" : "text-danger"}`}>{pnl?.toFixed(2)}</span>
          );
        },
      },
      {
        header: "RoundID",
        accessorKey: "roundID",
        enableColumnFilter: false,
        cell: (cell: any) => <span>{cell?.row?.original?.roundId}</span>,
      },
      {
        header: "",
        accessorKey: "action",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Button className="btn-sm" onClick={() => handleStatusUpdate(cell?.row?.original?._id)} variant="info">
            Status
          </Button>
        ),
      },
    ],
    [page, limit]
  );

  return (
    <>
      <TableContainer
        columns={columns || []}
        data={betData || []}
        isBordered={false}
        customPageSize={limit}
        isPagination={false}
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
      />

      <nav aria-label="Page navigation" className="px-3 d-flex flex-column flex-sm-row align-items-center">
        <TableEntry
          limit={limit}
          handlelimitFilter={(value: number) => handlePageFilter(page, value)}
        />
        <ListPagination
          listTotal={listTotal}
          handlePageFilter={(value: number) => handlePageFilter(value, limit)}
        />
      </nav>

      <Modal show={showList} onHide={() => setList(false)} className="zoomIn" scrollable>
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center">
            Parent List of{" "}
            <span className="text-muted fw-normal mx-2">({userName})</span>
          </div>
          <Button variant="light btn-sm" onClick={() => setList(false)}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>List</Modal.Body>
      </Modal>
    </>
  );
};

export default CasinoResultTable;
