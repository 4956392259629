import TableContainer from "Common/TableContainer";
import { useMemo, useState, } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import snackbarUtil from 'Utils/snackBarUtil';

interface Props {
  bannerData: bannerPayload[]
  getDeleted: (id: string) => Promise<void>
}

const TotalDepositTable = ({ }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    snackbarUtil.success("Copy Successfull")
    setTimeout(() => {
        setCopied(false);
    }, 3000);
  };

  const deposit = [
    {'no': '1', 'parentName': 'ic test', 'amount': '100', 'paymentMethod': 'UPI', 'accountDetails': '987654321654'},
    {'no': '2', 'parentName': 'ic test', 'amount': '100', 'paymentMethod': 'UPI', 'accountDetails': '987654321654'},
    {'no': '2', 'parentName': 'ic test', 'amount': '100', 'paymentMethod': 'UPI', 'accountDetails': '987654321654'},
  ]

  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "no",
        enableColumnFilter: false,
      },
      {
        header: "Username",
        accessorKey: "username",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <>
              <span>Testic</span>
              <CopyToClipboard text={"testic"} onCopy={handleCopy}>
                <i className='ms-1 align-middle ri-file-copy-line cursor-pointer'></i>
              </CopyToClipboard>
            </>
          );
        },
      },
      {
        header: "Parent Name",
        accessorKey: "parentName",
        enableColumnFilter: false,
      },
      {
        header: "Mobile",
        accessorKey: "mobile",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <>
              <span>9876543210</span>
              <CopyToClipboard text={"9876543210"} onCopy={handleCopy}>
                <i className='ms-1 align-middle ri-file-copy-line cursor-pointer'></i>
              </CopyToClipboard>
            </>
          );
        },
      },
      {
        header: "Amount",
        accessorKey: "amount",
        enableColumnFilter: false,
      },
      {
        header: "Payment method",
        accessorKey: "paymentMethod",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span className="text-danger">Reject</span>
          );
        },
      },
      {
        header: "Account Details",
        accessorKey: "accountDetails",
        enableColumnFilter: false,
      },
    ],
    []
  );

  return (
    <>
      <TableContainer
        columns={columns || []}
        data={deposit || []}
        isBordered={false}
        customPageSize={50}
        isPagination
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
      />
    </>
  );
};

export default TotalDepositTable;
