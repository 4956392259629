import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Modal } from 'react-bootstrap';
import BreadCrumb from 'Common/BreadCrumb';
import { settingServices } from 'Utils/setting/services';
import { useParams } from 'react-router-dom';



const Casino = () => {
    const [casinoData, setCasinoData] = useState<string>("")
    const { id, name } = useParams();
    const getLaunchUrl = async (game_id: string) => {
        const { response } = await settingServices.getLaunchUrl({
            game_id
        })

        if (response) {
            setCasinoData(response?.launchUrl)
        }
    }


    useEffect(() => {
        if (id) {
            getLaunchUrl(id);
        }
    }, [id])





    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Casino" pageTitle={name || ""} />

                    <Card>
                        <Card.Body style={{height:"80vh"}}>
                            <iframe width="100%" height="100%" src={casinoData} title="W3Schools Free Online Web Tutorials"></iframe>
                        </Card.Body>
                    </Card>

                </Container>
            </div>

        </React.Fragment>
    );
};

export default Casino;
